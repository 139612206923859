import React from 'react';

export const HomePageExtend = ({ customProp }) => {
  return (
    <div style={{textAlign: 'center'}}>
        <h4>Extended Component</h4>
        <p>Custom Prop: {customProp}</p>
    </div>
  )
}
