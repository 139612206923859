import React from 'react';
import { useProductContext } from '../../Contexts/ProductContext';
import './Carousel.css';
import { Link } from 'react-router-dom';


export default function HomePageProductSlider() {
  const { products } = useProductContext();

  // Filter products with promotional images and include the category

  console.log("products",products)
  const promotionalImages = products
    .filter(product => product.promotional_image)
    .map(product => ({
      image: product.promotional_image || '',
      category: product.parent_category,
    }));

    console.log("promotional images ======================= ",promotionalImages)

    return (
      <>
      <div className='common-mt'>
      <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          {promotionalImages.map((promo, index) => (
            
            <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
              <Link to={`/categorycards/${encodeURIComponent(promo.category)}`} className="image-link">
              <img
                src={`data:image/jpeg;base64,${atob(promo.image)}`}
                className="img-fluid w-100"
                //style={{ height: fixedImageHeight }}
                alt={`slider-img-${index}`}
                loading='lazy'
              />
              </Link>
            </div>
            
          ))}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      </div>
      </>
    );
  }
