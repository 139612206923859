import React from 'react';
import './Carousel2.css';
import { useProductContext } from '../../Contexts/ProductContext';
import { Link } from 'react-router-dom';

export const Carousel2 = () => {
  const { products } = useProductContext();

  // Group products by category
  const groupedProducts = {};
  products.forEach(product => {
    if (product.carousel_2) {
      if (!groupedProducts[product.category]) {
        groupedProducts[product.category] = [];
      }
      // Add product to the category, but limit to 4 products
      if (groupedProducts[product.category].length < 4) {
        groupedProducts[product.category].push(product);
      }
    }
  });

  console.log("grouped *************************** ", groupedProducts)

  // Function to truncate the product name
  const truncateName = (name, maxLength) => {
    if (name.length <= maxLength) return name;
    return name.split(' ').slice(0, maxLength).join(' ') + '...';
  };

  const truncateNameForURL = (name, maxLength) => {
    const words = name.split(' ');
    if (words.length <= maxLength) {
      return words.join('-'); // If the number of words is less than or equal to maxLength, use all words
    }
    return words.slice(0, maxLength).join('-'); // Otherwise, use only the first maxLength words
  };

  return (
    <div className="container-fluid">
      <div className="row gx-0">
        {Object.keys(groupedProducts).map(category => (
          <div className="col-xl-3 col-lg-12 col-md-12" key={category}>
            <h2 className='grid-heading' style={{ textAlign: 'center' }}>
              {category}
            </h2>
            <div className="css-175oi2r gdd-458345" style={{ border: "1px solid #ccc", borderRadius: "8px", marginBottom: '35px', boxShadow: "0 0 4px rgba(0, 0, 0, 0.3)" }}>
              {groupedProducts[category].map(product => (
                <div className="grid-formation grid-column-2" key={product.id}>
                  <div className="css-175oi2r css_mmrfs" style={{ marginRight: 4, marginLeft: 4, marginBottom: 8 }}>
                    <a className="_3n8fnaug" href={product.link}>
                      <div className='_3eeeee'>
                        <div>

                          <Link
                            to={`/${product.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(product.name, 4))}-${encodeURIComponent(product.id)}`}
                          >
                            <img
                              src={`data:image/jpeg;base64,${atob(product.image)}`}
                              alt={product.title}
                              style={{
                                width: "100%",
                                margin: "auto",
                                display: "block",
                                objectFit: "initial",
                                opacity: 1,
                                aspectRatio: "1 / 1"
                              }}
                            />
                            <div className="">
                              <div className="titlell product-name">{truncateName(product.name, 3)}</div>
                            </div>
                          </Link>

                        </div>

                      </div>

                    </a>
                  </div>
                </div>
              ))}
              <div className="am-cm-cardui-footer">
                <Link to={`/${groupedProducts[category][0].category_hierarchy.join('/')}`}
                  state={{ category: category }}
                  className="image-link see-more">See more</Link>
                {/* <Link to={`/${encodeURIComponent(groupedProducts[category][0].parent_category)}/${encodeURIComponent(category)}`}
                state = {{category: category}}
                className="image-link see-more">See more</Link> "See more" button */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel2;
