import React from 'react';
import { Link } from 'react-router-dom';
import { useProductContext } from '../../Contexts/ProductContext';
import './CategoryCarouselMobile.css';

const CategoryCarouselMobile = () => {
  const { products } = useProductContext();

  const decodeBase64Image = (base64) => {
    const decodedImage = new Image();
    decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
    return decodedImage.src;
  };

  // Filter out categories where the first product has on_website: false
  const categorizedProducts = Object.entries(products.reduce((acc, product) => {
    if (!acc[product.parent_category]) {
      acc[product.parent_category] = [];
    }
    acc[product.parent_category].push(product);
    return acc;
  }, {})).reduce((acc, [category, products]) => {
    if (products[0] && products[0].on_website) {
      acc[category] = products;
    }
    return acc;
  }, {});

  return (
    <div className="category-carousel-mobile-container">
      {/* <h2 className="categories-heading">Categories</h2> */}
      <div className="categories-slider">
        {Object.entries(categorizedProducts).map(([category, products]) => (
          <Link
            key={category}
            to={`/categorycards/${encodeURIComponent(category)}`}
            className="category-link"
          >
            <div className="category">
              <img
                src={decodeBase64Image(products[0].image)}
                alt={products[0].name}
                title={products[0].name}
                loading="lazy"
                className="category-image"
              />
              <div className="category-name">{category}</div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default CategoryCarouselMobile;
