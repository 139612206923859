import React, { useState, useEffect } from 'react';
import './MyOrders.css';
import noOrdersImage from './emptymyorders.png';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { format, parseISO } from 'date-fns';

export const MyOrders = ({ toggleComponents }) => {
    const loggedinUser = localStorage.getItem('user');
    console.log(JSON.stringify({ username: loggedinUser }));
    const [orders, setOrders] = useState([]);

    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const endpoint = '/myorders';
    const url = `${apiUrl}${endpoint}`;

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                if (loggedinUser) {
                    const response = await fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ username: loggedinUser })
                    });
                    const data = await response.json();
                    console.log("order details ========================= ", data);

                    const updatedOrders = data.result.map(order => ({
                        ...order,
                        products: order.products.filter(product => product.type !== 'service'),
                        total: order.products.reduce((acc, product) => acc + (product.price_unit * product.quantity), 0),  // Calculate total for each order
                        shippingCharges: order.products.filter(p => p.type === 'service').reduce((acc, product) => acc + (product.price_unit * product.quantity), 0)
                    }));

                    setOrders(updatedOrders);
                }
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };

        fetchOrders();
    }, [loggedinUser]);

    console.log("orders =================== ", orders);


    if (!loggedinUser) {
        return (
            <div className="my_order" style={{ minHeight: '1000px', textAlign: 'center' }}>
                <div className="container">
                <h5 style={{textAlign:'center', fontWeight: '300', marginTop:'300px'}}>Please Login To View Your Orders</h5>
                    {/* <p className="subtitle">Please log in to see your orders.</p> */}
                    <br/>
                    <Link to='/login'><Button size='small' variant='contained' color='primary'>Login</Button></Link>
                </div>
            </div>
        );
    }

    if (orders.length === 0) {
        return (
            <div className="my_order no-orders" style={{ minHeight: '1000px', textAlign: 'center' }}>
                <h5 style={{textAlign:'center', fontWeight: '300', marginTop:'300px'}}>You haven't placed any orders yet!<br/> Start shopping and make your first purchase today. Happy shopping!</h5>
                {/* <img src={noOrdersImage} alt="No Orders" style={{ marginTop: '200px' }} /> */}
                <br />
                <Link to='/'><Button size='small' variant='contained' color='primary'>Continue Shopping</Button></Link>
            </div>
        );
    }

    const truncateNameForURL = (name, maxLength) => {
        const words = name.split(' ');
        if (words.length <= maxLength) {
            return words.join('-'); // If the number of words is less than or equal to maxLength, use all words
        }
        return words.slice(0, maxLength).join('-');
    }

    return (
        <main>
            <div className="dlvr_adds" style={{ marginTop: '100px' }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="dlvr_cont">
                                {orders.map((order, index) => (
                                    <div key={index} className="orderInfo">
                                        <div className="orderId">
                                            <span className="orderName">Order # </span>{" "}
                                            <span className="orderIdinfo">{order.order_id}</span>
                                        </div>
                                        <div className="orderDetails">
                                            <div className="orderDate">
                                                <span>Order Placed: {format(parseISO(order.order_date), 'dd MMMM yyyy')}</span>
                                            </div>
                                        </div>
                                        <div className="order_cart_page">
                                            <div className="order_cart_body">
                                                <table>
                                                    <tbody>
                                                        {order.products.map((product, idx) => (
                                                            <tr key={idx} className="cart-item" id={`cartpro-${product.product_id}`}>
                                                                <td className="item-media">

                                                                    <div className="cart-image">
                                                                        {product.name}
                                                                        <Link className="img"
                                                                            to={`/${product.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(product.product_name, 4))}-${encodeURIComponent(product.product_id)}`}
                                                                        >
                                                                            <img src={`data:image/jpeg;base64,${atob(product.image_base64)}`} className="img-fluid item-img" alt="Product" loading="lazy" width="150" height="139" />
                                                                        </Link>
                                                                        <div className="item-info">
                                                                            <Link className="img"
                                                                                to={`/${product.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(product.product_name, 4))}-${encodeURIComponent(product.product_id)}`}
                                                                            >
                                                                                {product.product_name}
                                                                            </Link>
                                                                            <div className="item-option">
                                                                                <span className="qty">
                                                                                    <span className="heading">Qty: </span>
                                                                                    <span style={{ marginRight: '12px' }}>{product.quantity} </span>
                                                                                    <span> Price: </span><sup> $ </sup>{product.price_unit}
                                                                                </span>
                                                                                {/* <span className="item-pr">
                                                                                    <sup>$</sup>{product.price_unit}
                                                                                </span> */}
                                                                                <br />
                                                                                <span className="item-pr">
                                                                                    <Stack spacing={1} direction="row">
                                                                                        <Link to='/order_details' state={{ order: order, product:product }}>
                                                                                            <Button size='small' variant='contained'>Track</Button>
                                                                                        </Link>
                                                                                        <Button size='small' variant='outlined'>Return</Button>
                                                                                        {/* <Button size='small' variant='contained'>Return</Button> */}
                                                                                        <Button size='small' variant='outlined' color='error'>Cancel</Button>
                                                                                    </Stack>
                                                                                </span>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </td>
                                                                <td className="cart-item-totals">
                                                                    <div className="item-price">
                                                                        <p className="mb-0" style={{ fontSize: '19px', color: 'black' }}>Status</p>
                                                                        <span className="price-item price-item-regular" style={{ fontSize: '16px', fontWeight: 550 }}>
                                                                            In-Transit
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td className="cart-item-totals" >
                                                                    <div className="item-date">
                                                                        <p className="mb-0" style={{ fontSize: '19px', color: 'black' }}>Expected Delivery </p>
                                                                        <span className="price-item price-item-regular" style={{ fontSize: '16px', fontWeight: 550, color: 'gray' }}>
                                                                            15 May 2024
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <div className="" style={{ fontSize: 20, fontWeight: 800, textAlign: 'right', marginBottom: '20px', marginTop: '10px' }}>
                                                    <a href="#" className="odr_bottom_custom_button">
                                                        <sup style={{ fontWeight: '400' }}>Total</sup> <sup style={{ marginLeft: '10px', fontWeight: '300' }}>$</sup><span style={{ fontSize: '18px', fontWeight: 300 }}>{order.total}</span>
                                                        <div className="odr_tooltip">
                                                            {order.products.map((product, idx) => (
                                                                <div key={idx} className="or_tltip_cont1" style={{ textAlign: 'left' }}>
                                                                    <div>{product.product_name}</div>
                                                                    <div style={{ fontWeight: '400', fontSize: '18px' }}><sup>$ </sup>{product.price_unit * product.quantity}</div>
                                                                </div>
                                                            ))}
                                                            <div className="or_tltip_cont3">
                                                                <div className="or_tltip_cont3_pr">
                                                                    <span className="oe_tip_31">Cart Subtotal</span>
                                                                    <span className="oe_tip_42"><sup>$ </sup>{order.products.reduce((acc, product) => acc + (product.price_unit * product.quantity), 0)}</span>
                                                                </div>
                                                                <div className="or_tltip_cont3_pr">
                                                                    <span className="oe_tip_31">Shipping Charges</span>
                                                                    <span className="oe_tip_42"><sup>$ </sup>{order.shippingCharges}</span> {/* Assuming free shipping for simplicity */}
                                                                </div>
                                                            </div>
                                                            <div className="or_tltip_cont4">
                                                                <span className="oe_tip_41" style={{ fontWeight: '400' }}>Total</span>
                                                                <span className="oe_tip_42"><sup>$ </sup>{order.total}</span>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>

                                                {/* <div>Total for this order: ${order.total}</div> */}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rs_order" style={{ marginTop: '70px' }}>
                <div className="container">
                    <h2>My Orders</h2>
                    {/* <div className="rs_order_src">
                        <div>
                            <i className="fas fa-search" style={{ color: "#00b982" }} /> Search all orders
                        </div>
                        <div className="rs_order_tl">
                            Filter{" "}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="10px"
                                height="10px"
                                viewBox="0 0 50 80"
                                xmlSpace="preserve"
                            >
                                <polyline
                                    fill="none"
                                    stroke="#000000"
                                    strokeWidth={10}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    points="
0.375,0.375 45.63,38.087 0.375,75.8 "
                                />
                            </svg>
                        </div>
                        <br/>
                    </div> */}
                    {orders.map((order, index) => (
                        <div key={index} className="rs_order_group">
                            <div className="rs_order_header">
                                <div className="orderName">Order ID: {order.order_id}</div>
                                {/* <div className="rs_order_date">Order Placed: {format(parseISO(order.order_date), 'dd MMM yyyy')}</div> */}
                            </div>
                            {order.products.map((product, idx) => (
                                <div key={idx} className="rs_product">
                                    <div className="rs_order_im">
                                        {/* Optional product image */}
                                        <img src={`data:image/jpeg;base64,${atob(product.image_base64)}`} className="img-fluid item-img" alt="Product" loading="lazy" width="150" height="139" />
                                    </div>
                                    <div className="rs_order_way">
                                        <div className="rs_order_ti">{product.product_name}</div>
                                        {/* <div className="rs_order_subti">Quantity: {product.quantity}</div>
                                        <div className="rs_order_subti">Price: ${product.price_unit}</div> */}
                                    </div>
                                    <div className="rs_order_nst">
                                        <Link to='/order_details' state={{ order: order, product:product }}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                width="10px"
                                                height="10px"
                                                viewBox="0 0 50 80"
                                                xmlSpace="preserve"
                                            // onClick={() => toggleComponents(order)}
                                            >
                                                <polyline
                                                    fill="none"
                                                    stroke="#000000"
                                                    strokeWidth={10}
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    points="
0.375,0.375 45.63,38.087 0.375,75.8 "
                                                />
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>


        </main>
    );
}

export default MyOrders;
