import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField'; // Import TextField from Material-UI
import './Login.css';
import image from './eShop-LOGO-BlackE.png';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { FacebookLogin } from '@greatsumini/react-facebook-login';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { FacebookLoginButton } from 'react-social-login-buttons';
import { createButton } from "react-social-login-buttons";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FacebookIcon from '@mui/icons-material/Facebook';
import Button from '@mui/material/Button';
import { height, width } from '@mui/system';
import { useCompanyContext } from '../../Contexts/CompanyContext';

const Login = () => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const endpoint = '/login';
  const loginUrl = `${apiUrl}${endpoint}`;
  const companyID = localStorage.getItem('companyID');
  const { companyDetails } = useCompanyContext();
  const logoSrc = companyDetails ? `data:image/png;base64,${atob(companyDetails.company_image)}` : '';
  const dynamicURL = companyID ? `/home/${companyID}` : '/';
  const [formData, setFormData] = useState({
    useremail: '',
    password: '',
  });
  const [newAddress, setNewAddress] = useState({
    email: '',
    new_password: '',
    confirm_new_password: ''

  });
  const [emailExists, setEmailExists] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [otpVerified, setOtpVeified] = useState(false);
  const [otpFromServer, setOtpFromServer] = useState('');
  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    window.location.reload()
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleReChange = (e) => {
    setNewAddress({
      ...newAddress,
      [e.target.name]: e.target.value
    });
  };

  const submitNewPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:9001/forgot_password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: newAddress.email })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("data ===================== ", data);
      if (data.result.success === 'NO') {
        alert(data.result.message, 'data of the forgot password')
        setEmailExists(false);
        setOpen(true);
        // Handle if email already exist
      }
      if (data.result.success === 'YES') {
        alert(data.result.message)
        setEmailExists(true); // Email exists, set to true
        setEmailVerified(true);
        setOtpFromServer(data.result.emailOtp);
      }
    } catch (error) {
      console.error("Failed to add address:", error);
    }
  };

  const validateOtp = () => {
    if (newAddress.passcode === otpFromServer) {
      setOtpVeified(true);
    } else {
      alert("invalid OTP !")
    }
  };

  const updatePassword = async (e) => {
    e.preventDefault();

    if (newAddress.new_password !== newAddress.confirm_new_password) {
      alert("Passwords do not match");
    }
    const response = await fetch('http://localhost:9001/update_password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: newAddress.email,
        password: newAddress.new_password,
        confirmPassword: newAddress.confirm_new_password,
      }),
    });

    const responseData = await response.json();
    console.log(responseData);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(loginUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const responseData = await response.json();
        const resultData = JSON.parse(responseData.result);
        if (resultData.success === 'YES') {
          localStorage.setItem('user', JSON.stringify({ user: resultData.username, email: resultData.user_email, user_id: resultData.id }));
          // Redirect to home page after successful login
          if (location.state && location.state.fromCheckout) {
            navigate('/cart');
          } else {
            // Redirect to home page after successful login
            window.location.href = '/';
          }
        }
        if (resultData.success === 'NO') {
          localStorage.removeItem('user');
          // Handle failed login
          Swal.fire({
            icon: 'info',
            title: 'Error',
            text: resultData.message,
          });
        }
      } else {
        console.error('Failed to submit form:', response.statusText);
        localStorage.removeItem('user');
        // Handle server error
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Internal server error while submit Form.',
        });
      }
    } catch (error) {
      localStorage.removeItem('user');
      console.error('An error occurred:', error);
      // Handle internal server error
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Internal Sever Error.',
      });
    }
  };

  const responseMessage = async (response) => {
    console.log(response, ' google response----------->')
    const decoded = jwtDecode(response.credential)
    const user_email = decoded.email
    const user_name = decoded.name
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const endpoint = '/login_googole';
    const registerUrlGoogle = `${apiUrl}${endpoint}`;
    console.log("google ===================== ", registerUrlGoogle)
    const formData = {
      username: user_name,
      useremail: user_email,
    };
    console.log(formData)
    try {
      const responseValue = await fetch(registerUrlGoogle, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      console.log(responseValue, 'response')
      if (responseValue.ok) {
        const responseData = await responseValue.json();
        console.log(responseData, 'responseData')
        const resultData = JSON.parse(responseData.result);
        console.log(resultData, 'resultdata')
        if (resultData.success === 'YES') {
          localStorage.setItem('user', JSON.stringify({ user: resultData.username, email: resultData.user_email, user_id: resultData.id }));
          console.log(localStorage, 'localstorage............')

          // Redirect to home page after successful login
          window.location.href = '/';
        }
        if (resultData.success === 'NO') {
          localStorage.removeItem('user');
          localStorage.clear();
          // Handle failed login
        }
      } else {
        console.error('Failed to submit form:', response.statusText);
      }
    } catch (error) {
      localStorage.removeItem('user');
      console.error('An error occurred:', error);
      // Handle internal server error
    }
  };


  const responseMessageFacebook = async (response) => {
    const profile = response.data
    console.log(profile.email)
    console.log(response, 'response----------->')

    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const endpoint = '/login_googole';
    const registerUrlGoogle = `${apiUrl}${endpoint}`;

    const formData = {
      username: profile.name,
      useremail: profile.email,
    };
    console.log(formData)
    try {
      const responseValue = await fetch(registerUrlGoogle, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      console.log(responseValue, 'response')
      if (responseValue.ok) {
        const responseData = await responseValue.json();
        console.log(responseData, 'responseData')
        const resultData = JSON.parse(responseData.result);
        console.log(resultData, 'resultdata')
        if (resultData.success === 'YES') {
          localStorage.setItem('user', JSON.stringify({ user: resultData.username, email: resultData.user_email, user_id: resultData.id }));
          console.log(localStorage, 'localstorage............')

          // Redirect to home page after successful login
          window.location.href = '/';
        }
        if (resultData.success === 'NO') {
          localStorage.removeItem('user');
          localStorage.clear();
          // Handle failed login
        }
      } else {
        console.error('Failed to submit form:', response.statusText);
      }
    } catch (error) {
      localStorage.removeItem('user');
      console.error('An error occurred:', error);
      // Handle internal server error
    }

  }

  const errorMessage = (error) => {
    console.log(error);
  };

  return (
    <>
    <div className="page-container">
      <div className="e_login">
        <div className="login">
          <div className="e_login_content">
            <div className="">
              <Link to={dynamicURL}><img src={logoSrc} style={{width:'200px',height:'50px'}} alt=""/></Link>
            </div>
            <br />
            <h1 className="e_head">Sign Up</h1>
            <Form onSubmit={handleSubmit}>
              <TextField
                id="useremail"
                label="Email address"
                type="email"
                size="small"
                // placeholder="Enter Email"
                name="useremail"
                value={formData.useremail}
                onChange={handleChange}
                required
                variant="outlined"
                fullWidth
                margin="normal"
              // Decrease the margin-bottom
              />
              <TextField
                id="password"
                label="Password"
                type="password"
                // placeholder="Enter Password"
                size="small"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                variant="outlined"
                fullWidth
                margin="normal"
                sx={{ mb: 0 }} // Decrease the margin-bottom
              />

              {/* <input type="email" placeholder="email" />
              <input type="password" placeholder="password" /> */}
              {/* <span className="remember">I accept term</span> */}
              <div class="forget"><Link to='/forgot_password'>Forgot Password?</Link></div>
              <span className="clearfix" />
              <Button variant='contained' type="submit" sx={{width:'100%'}}>Log In</Button>
            </Form>
            <span className="loginwith" style={{fontWeight:'300'}}>Or Connect with</span>
            {/* <a href="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-facebook"
              >
                <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
              </svg>
            </a>
            <a href="">
              <svg
                className="feather feather-twitter sc-dnqmqq jxshSx"
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                aria-hidden="true"
              >
                <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" />
              </svg>
            </a>
            <a href="">
              <svg
                className="feather feather-github sc-dnqmqq jxshSx"
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                aria-hidden="true"
              >
                <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22" />
              </svg>
            </a>
            <a href="#">
              {" "}
              <svg
                className="feather feather-linkedin sc-dnqmqq jxshSx"
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                aria-hidden="true"
              >
                <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
                <rect x={2} y={9} width={4} height={12} />
                <circle cx={4} cy={4} r={2} />
              </svg>
            </a> */}
            <br />
            <div className="login-container">
              <GoogleLogin
                className="custom-google-login"
                title="Login"
                onSuccess={responseMessage}
                onError={errorMessage}
              />
              <LoginSocialFacebook
                appId="1078994883190453"
                onResolve={responseMessageFacebook}
                onReject={(error) => {
                  console.log(error);
                }}
              >
                <FacebookLoginButton style={{ width: '210px', height: '39px' }}>Facebook</FacebookLoginButton>
              </LoginSocialFacebook>
            </div>
            <br />
            <div className="e_register">
              <p>
                New Customer ?{" "}
                <span>
                  <Link to='/register' state={{ fromCart: location.state }}>Register here</Link>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Login;