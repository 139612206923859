import React, { useState, useEffect } from 'react';
import './ChildCategoryCards.css';
import { Link, useLocation } from 'react-router-dom';
import { useProductContext } from '../../Contexts/ProductContext';
import image from './Ecommerce-Doodle-Banner.jpg';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Slider, Typography } from '@mui/material';

export default function ChildCategoryCards() {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [categoryImage, setCategoryImage] = useState(null);
  const { products } = useProductContext();
  const location = useLocation();
  const category = location.state?.category || decodeURIComponent(location.pathname.split('/').pop());

  console.log("category", category);

  // Filter products based on the selected category
  const filteredProducts = products.filter((product) => {
    const categoryIndex = product.category_hierarchy.indexOf(category);
    // Check if 'category' is in the product's hierarchy
    // and it's either the same category or there are more categories after it in the hierarchy
    return product.on_website &&
      categoryIndex >= 0 &&
      (categoryIndex === product.category_hierarchy.length - 1 || categoryIndex < product.category_hierarchy.length - 1);
  });

  console.log("products ==== ", products);
  console.log("filtered products ==== ", filteredProducts);

  const categories = [category];

  // Extract unique brands from filtered products
  const brands = [...new Set(filteredProducts.map(product => product.brand))];

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [priceRange, setPriceRange] = useState([0, 1000]); // Default price range

  // Function to handle category selection
  const handleCategorySelect = (selectedCat) => {
    // If the clicked category is already selected, unselect it
    setSelectedCategory(selectedCategory === selectedCat ? null : selectedCat);
  };

  // Function to handle brand selection
  const handleBrandSelect = (selectedBr) => {
    // If the clicked brand is already selected, unselect it
    setSelectedBrand(selectedBrand === selectedBr ? null : selectedBr);
  };

  // Function to handle price range change
  const handlePriceChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  // Filter products based on selected brand and price range
  const finalFilteredProducts = filteredProducts.filter(product => {
    const isBrandMatch = selectedBrand ? product.brand === selectedBrand : true;
    const isPriceMatch = product.price >= priceRange[0] && product.price <= priceRange[1];
    return isBrandMatch && isPriceMatch;
  });

  const decodeBase64Image = (base64) => {
    const decodedImage = new Image();
    decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
    return decodedImage.src;
  };

  useEffect(() => {
    const toggleSidebar = () => {
      const sidemenu = document.querySelector(".filter_sidebar");
      const closeX = document.querySelector(".filter_close");
      const overlay = document.querySelector(".filter_overlay");

      if (sidemenu && closeX && overlay) {
        sidemenu.classList.toggle("active");
        closeX.classList.toggle("active");
        overlay.classList.toggle("active");
      }
    };

    const closeSidebar = () => {
      const sidemenu = document.querySelector(".filter_sidebar");
      const closeX = document.querySelector(".filter_close");
      const overlay = document.querySelector(".filter_overlay");

      if (sidemenu && closeX && overlay) {
        sidemenu.classList.remove("active");
        closeX.classList.remove("active");
        overlay.classList.remove("active");
      }
    };

    const filterShow = document.getElementById("filter_show");
    const filterClose = document.querySelector(".filter_close");

    if (filterShow) {
      filterShow.addEventListener("click", toggleSidebar);
    }
    if (filterClose) {
      filterClose.addEventListener("click", closeSidebar);
    }

    return () => {
      if (filterShow) {
        filterShow.removeEventListener("click", toggleSidebar);
      }
      if (filterClose) {
        filterClose.removeEventListener("click", closeSidebar);
      }
    };
  }, []);

  useEffect(() => {
    const sendPostRequest = async () => {
      try {
        const response = await fetch(`${apiUrl}/get_category_image`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ category: category })
        });

        if (!response.ok) {
          throw new Error('Failed to send POST request');
        }

        // Handle success response
        const data = await response.json();
        setCategoryImage(data.result);
      } catch (error) {
        // Handle errors
        console.error('Error sending POST request:', error.message);
      }
    };

    // Call the function immediately
    sendPostRequest();
  }, [category]);

  const truncateNameForURL = (name, maxLength) => {
    const words = name.split(' ');
    if (words.length <= maxLength) {
      return words.join('-'); // If the number of words is less than or equal to maxLength, use all words
    }
    return words.slice(0, maxLength).join('-'); // Otherwise, use only the first maxLength words
  };

  return (
    <>
      <div className='common-mt' style={{ minHeight: '1000px' }}>
        <section className="breadcrumb-area">
          <div className="ratio">
            {categoryImage ? (
              <img
                src={decodeBase64Image(categoryImage)}
                alt="Category Imagewecfdc"
                className="img-fluid ls-is-cached lazyloaded"
                width={1920}
                height={300.0}
                decoding="async"
                loading="lazy"
                sizes="100vw"
              />
            ) : (
              <img
                src={image}
                alt=""
                className="img-fluid ls-is-cached lazyloaded"
                width={1920}
                height={300.0}
                decoding="async"
                loading="lazy"
                sizes="100vw"
              />
            )}
          </div>
          <div className="container">
            <div className="row">
              <div className="col-">
                <div className="breadcrumb-index">
                  <ul className="breadcrumb-list">
                    <li className="breadcrumb-item">
                      <a href="/" title="Back to the home page">
                        Home
                      </a>
                    </li>
                  </ul>
                  <div className="breadcrumb-title">
                    <h1>{category}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="main-content-wrap" style={{ background: "aliceblue" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="shop-top-bar">
                  <div className="category_filter">
                    <div id="filter_show"><FilterAltOutlinedIcon />Filter</div>
                    <div className="filter_sidebar">
                      <div className="filter_close filter_close-btn" />
                      <h2 className="pro-grli-title">Categories</h2>
                      <div className="filter_menu-bar">
                        <div className="e-desk-left">
                          <ul>
                            {categories.map((cat, index) => (
                              <li key={index}>
                                <input type="checkbox"
                                  checked={selectedCategory === cat} // Check if the category is selected
                                  onChange={() => handleCategorySelect(cat)} />
                                <label htmlFor={cat}>{cat}</label>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <h2 className="pro-grli-title">Brands</h2>
                      <div className="filter_menu-bar">
                        <div className="e-desk-left">
                          <ul>
                            {brands.map((br, index) => (
                              <li key={index}>
                                <input type="checkbox"
                                  checked={selectedBrand === br} // Check if the brand is selected
                                  onChange={() => handleBrandSelect(br)} />
                                <label htmlFor={br}>{br}</label>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <h2 className="pro-grli-title">Price Range</h2>
                      <div className="filter_menu-bar">
                        <div className="e-desk-left">
                          <Typography id="range-slider" gutterBottom>
                            Price range
                          </Typography>
                          <Slider
                            value={priceRange}
                            onChange={handlePriceChange}
                            valueLabelDisplay="auto"
                            min={0}
                            max={1000}
                            step={10}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="filter_overlay" />
                  </div>
                </div>
                <div className="pro-grli-wrapper left-side-wrap">
                  <div className="pro-grli-wrap product-grid">
                    <div className="pro-grlis">
                      <h2 className="pro-grli-title">Results</h2>
                    </div>
                    <div className="ctg_grid-area gdd_ppppppp">
                      {finalFilteredProducts.map((product, index) => (
                        <div className="ctg-formation ctg_grid" key={index}>
                          <div className="ctg_grid-cont">
                            <Link
                              to={`/${product.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(product.name, 4))}-${encodeURIComponent(product.id)}`}
                            >
                              <div className="ctg_grid-akrcont">
                                <div>
                                  <img
                                    src={decodeBase64Image(product.image)}
                                    style={{
                                      width: "100%",
                                      margin: "auto",
                                      display: "block",
                                      objectFit: "contain",
                                      opacity: 1,
                                      aspectRatio: "1 / 1"
                                    }}
                                    alt={product.name}
                                  />
                                </div>
                                <div className="ctg_grif-content">
                                  <div className="ctg_grid-title">
                                    <h2 className="product-name">{product.name}</h2>
                                  </div>
                                  <div className="ctg-row">
                                    <span className="e-price-symbol">${product.price}</span>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="pro-grli-wrap product-sidebar">
                    <div className="pro-grlis">
                      <h2 className="pro-grli-title">Categories</h2>
                    </div>
                    <div className="filter_menu-bar">
                      <div className="e-desk-left">
                        <ul>
                          {categories.map((cat, index) => (
                            <li key={index}>
                              <input type="checkbox"
                                checked={selectedCategory === cat} // Check if the category is selected
                                onChange={() => handleCategorySelect(cat)} />
                              <label htmlFor={cat}>{cat}</label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="pro-grlis">
                      <h2 className="pro-grli-title">Brands</h2>
                    </div>
                    <div className="filter_menu-bar">
                      <div className="e-desk-left">
                        <ul>
                          {brands.map((br, index) => (
                            <li key={index}>
                              <input type="checkbox"
                                checked={selectedBrand === br} // Check if the brand is selected
                                onChange={() => handleBrandSelect(br)} />
                              <label htmlFor={br}>{br}</label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="pro-grlis">
                      <h2 className="pro-grli-title">Price Range</h2>
                    </div>
                    <div className="filter_menu-bar">
                      <div className="e-desk-left">
                        <Slider
                          value={priceRange}
                          onChange={handlePriceChange}
                          valueLabelDisplay="auto"
                          min={0}
                          max={1000}
                          step={10}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
