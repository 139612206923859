import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Cart.css'
import { useCartContext } from '../../Contexts/CartContext';
import { useProductContext } from '../../Contexts/ProductContext';
import rsa from './right-arrow.png';
import delIcon from './delete_6861294.png';
import emptyCart from './emptycart.png';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import StepBar from '../StepBar/StepBar';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function Cart() {
  const { products } = useProductContext();
  const { cartItems, removeFromCart, decreaseQuantity, increaseQuantity } = useCartContext();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const wishlist_endpoint = '/add_to_wishlist';
  const wishlistUrl = `${apiUrl}${wishlist_endpoint}`;
  // const secretKey = process.env.REACT_APP_SECRET_KEY;
  const endpoint = '/checkout';
  const checkouturl = `${apiUrl}${endpoint}`;
  const [isInWishlist, setIsInWishlist] = useState(false);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [showQuantitySnackbar, setShowQuantitySnackbar] = useState(false);

  const filteredProducts = useMemo(() => {
    const categories = new Set(cartItems.map(item => item.category));
    return products.filter(product => categories.has(product.category));
  }, [cartItems, products]);
  

  // useEffect(() => {
  //   const categories = new Set(cartItems.map(item => item.category));
  //   setUniqueCategories([...categories]);
  // }, [cartItems]);

  // // Filter products based on unique categories
  // const filteredProducts = products.filter(product =>
  //   uniqueCategories.includes(product.category)
  // );

  console.log("unique categories set ========================= ", uniqueCategories);
  console.log("filtered products ========================= ", filteredProducts);

  const carouselOptions = {
    items: 4,
    loop: false,
    margin: 20,
    nav: true,
    dots: false,
    autoplay: true, // Enable autoplay
    autoplayTimeout: 2000,
    responsive: {
      0: {
        items: 1 // Show only one item in mobile view
      },
      479: {
        items: 2 // Show two items in tablet view
      },
      700: {
        items: 3
      },
      800: {
        items: 4 // Show four items in desktop view
      },
      1093: {
        items: 5
      }
    }
  };


  // const handleRemoveFromCart = (productId) => {
  //   removeFromCart(productId);
  // };

  const removeFromCartWithApi = async (productId) => {
    try {
      removeFromCart(productId);

      const currentUser = JSON.parse(localStorage.getItem('user'));

      if (currentUser && currentUser.user) {
        const response = await fetch(`${apiUrl}/remove_from_cart`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id, productId }),
        });

        if (!response.ok) {
          throw new Error('Failed to remove product from cart');
        }

      } else {
        console.error('User not logged in');
      }
    } catch (error) {
      console.error('Error removing product from cart:', error);
    }
  };

  const handleIncreaseQuantity = async (productId) => {
    const product = products.find(p => p.id === productId);
    const productInCart = cartItems.find(item => item.id === productId);

    // Check if the quantity constraint should be applied
    const isEshopQuantityLimited = product.on_hand_quantity_eshop === "True";
    if (isEshopQuantityLimited) {

      if (productInCart && productInCart.quantity >= product.on_hand_quantity) {
        setShowQuantitySnackbar(true);
        console.error('Cannot increase quantity. Quantity limit reached.');
        return;
      }
    }

    // Increase the quantity in the local state
    increaseQuantity(productId);

    const currentUser = JSON.parse(localStorage.getItem('user'));

    if (currentUser && currentUser.user_id) {
      const response = await fetch(`${apiUrl}/increase_product_qty_in_cart`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: currentUser.user_id, productId }),
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log('Quantity increased:', responseData);
      } else {
        console.error('Failed to increase quantity');
      }
    } else {
      console.error('User not logged in');
    }
  };

  const handleDecreaseQuantityBackend = async (productId) => {
    decreaseQuantity(productId); // Update the quantity in the local state

    const currentUser = JSON.parse(localStorage.getItem('user'));

    // Check if the current user is logged in
    if (currentUser && currentUser.user) {
      try {
        // Send a POST request to the backend to update the quantity
        const response = await fetch(`${apiUrl}/decrease_product_qty_in_cart`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id, productId }),
        });

        if (!response.ok) {
          throw new Error('Failed to decrease quantity');
        }

        const responseData = await response.json();
        console.log(responseData);
      } catch (error) {
        console.error('Error decrease quantity:', error);
      }
    }
  };

  const totalAmount = cartItems.reduce((total, item) => total + (item.price * item.quantity), 0);

  //proceed to checkout function
  const handleProceedToCheckout = async () => {
    const isLoggedIn = localStorage.getItem('user');


    if (isLoggedIn) {
      try {
        const body = {
          user: isLoggedIn ? JSON.parse(isLoggedIn).user : null,
          email: isLoggedIn ? JSON.parse(isLoggedIn).email : null,
          cartItems: cartItems.map(item => ({
            id : item.id,
            variant_id: item.variantId,
            name: item.name,
            price: item.price,
            quantity: item.quantity
          }))
        };

        // console.log("body ====== ",body);

        const response = await fetch(checkouturl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),

        });
        const data = await response.json();
        console.log("data of sale ordersssss ========",data);
        navigate('/checkout', { state: { cartItems, totalAmount, additionalData: data, filteredProducts: filteredProducts } }); //navigate to payment page

      } catch (error) {
        console.error('Error:', error);
        // Handle error if API call fails
      }
    } else {
      // If the user is not logged in
      navigate('/login', { state: { fromCheckout: true } });
    }
  };

  const addToWishlist = async (productDetail) => {
    
    try {
      const currentUserData = localStorage.getItem('user');
      const currentUser = JSON.parse(currentUserData);

      if (currentUser && currentUser.user_id) {
        const response = await fetch(wishlistUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id, product_detail: productDetail }),
        });
        if (!response.ok) {
          throw new Error('Failed to add to wishlist');
        }
        setIsInWishlist(true);
        removeFromCartWithApi(productDetail.id);
      } else {
        console.error('User ID not found in currentUser data');
        navigate('/login');
      }
    } catch (error) {
      console.error('Error adding to wishlist:', error);
    }
  };
  

  const truncateNameForURL = (name, maxLength) => {
    const words = name.split(' ');
    if (words.length <= maxLength) {
      return words.join('-'); // If the number of words is less than or equal to maxLength, use all words
    }
    return words.slice(0, maxLength).join('-'); // Otherwise, use only the first maxLength words
  };


  return (
    <main>
      {cartItems.length === 0 ? (
        <div className="empty-cart" style={{ minHeight: '1000px', textAlign: 'center' }}>
          <img src={emptyCart} alt="Empty Cart" style={{ maxWidth: '100%', height: 'auto', marginTop: '200px' }} />
          <br />
          <Link to='/'><Button variant='contained'>Continue Shopping</Button></Link>
        </div>
      ) : (
        <>
          <br />
          <br />
          <br />
          <br />
          <div className="e-add-cart" >
            <div className="e-add-cart">
              <div className="container-fluid">
              <StepBar/>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="table-content">
                      <table>
                        <thead>
                          <tr>
                            <th colSpan={2}>Product</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Total</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {cartItems.map((item) => (
                            <tr key={item.id}>
                              <td className="ec-cart-pro-name">
                                <a href="">
                                  {/* <img
                                  className="ec-cart-pro-img"
                                  src={`data:image/jpeg;base64,${atob(item.image)}`}
                                  alt=""
                                /> */}
                                </a>
                              </td>
                              <td data-label="Product">
                                <span className="tilte">{item.name}</span>
                                <br />
                                <span className="subtitle">Color: Red</span>
                                <span className="subtitle">Size: M</span>
                                <Stack sx={{ marginTop: '12px' }} direction="row" spacing={1}>
                                  <span>
                                    <Button variant='outlined' size="small" onClick={() => addToWishlist(item)}>Move to Wishlist</Button>
                                  </span>
                                  <span>
                                    <Button variant='outlined' size="small" color="error" onClick={() => removeFromCartWithApi(item.id)}>
                                      Delete
                                    </Button>
                                  </span>
                                </Stack>
                              </td>
                              <td data-label="Price" className="ec-cart-pro-price">
                                <span className="amount pr_dtls"><span className='pr_name'>	Price	</span><span>${item.price}</span></span>
                              </td>
                              <td data-label="QTY">
                                <div className="qty-buttons pr_dtls">
                                  <span className='pr_dtls'>
                                    <span className='pr_name' style={{ lineHeight: '29px', paddingRight: '10px' }}>Quantity	</span>
                                    <span style={{ border: '1px solid #e1cece' }}>
                                      <button className="minus" onClick={() => handleDecreaseQuantityBackend(item.id)}>-</button>
                                      <span className="qty" style={{ padding: '7px' }}>{item.quantity}</span>
                                      <button className="plus" onClick={() => handleIncreaseQuantity(item.id)}>+</button></span></span>
                                </div>
                              </td>
                              <td data-label="Total"><span className='pr_dtls'><span className='pr_name'>Total	</span><span>${item.price * item.quantity}</span></span></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    {/* <style
                dangerouslySetInnerHTML={{
                  __html:
                    "\n                      .ship-cart {\n                        padding: 20px;\n                        box-shadow: 0 30px 70px #010f1c1a\n                      }\n\n                      .ship-cart .pull-right {\n                        font-weight: 500;\n                        float: right !important;\n                      }\n\n                      .ship-cart h2 {\n                        font-size: 21px;\n                        font-weight: 700;\n                        border-bottom: 1px solid #E0E2E3;\n                        padding-bottom: 15px;\n                      }\n\n                      .ship-cart ul {\n                        padding-left: 0;\n                        list-style: none;\n                      }\n\n                      .ship-cart li {\n                        line-height: 30px;\n                      }\n\n                      .ship-cart li label {\n                        font-size: 16px;\n                        font-weight: 500;\n                      }\n\n                      .ship-total-price {\n                        padding-top: 10px;\n                        border-top: 1px solid #E0E2E3;\n                      }\n\n                      .ship-cart-checkout {\n                        font-size: 16px;\n                        font-weight: 500;\n                        color: white;\n                        background-color: #000;\n                        display: block;\n                        padding: 10px 30px;\n                        text-align: center;\n                        text-decoration: none;\n                      }\n\n                      .ship-cart-checkout:hover {\n                        color: white;\n                        background-color: #0989ff;\n                      }\n                "
                }}
              /> */}
                    <div className="ship-cart">
                      <div className="ship-cart-pr">
                        <h2>Shopping Cart</h2>
                        <ul>
                          <li>
                            <label>Cart subtotal</label>
                            <div className="value pull-right" style={{ color: "#555" }}>
                              ${totalAmount.toFixed(2)}
                            </div>
                          </li>
                          {/*<li>
                          <label>Shipping</label>
                          <div className="value pull-right" style={{ color: "#555" }}>
                            $0
                          </div>
                        </li>*/}
                        </ul>
                        <div className="hr-border" />
                        <ul className="ship-total-price">
                          <li>
                            <label style={{ fontWeight: 700 }}>Order total</label>
                            <div className="value pull-right" style={{ fontWeight: 700 }}>
                              ${totalAmount.toFixed(2)}
                            </div>
                          </li>
                        </ul>
                        <div className="ship-cart-shop">
                          <Button variant="contained" onClick={handleProceedToCheckout} sx={{ width: '100%' }}
                          >
                            Proceed to Checkout
                          </Button>
                          <br />
                          <br />
                          {/* <Link to={'/'}>
                          <Button variant="outlined" size='large'>
                            Continue Shopping
                          </Button>
                        </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div>
            <br/>
            <h3 style={{ textAlign: 'center' }}>Related products</h3>
            <br/>
            <OwlCarousel {...carouselOptions}>
              {filteredProducts.map((item, index) => (
                <div key={index} className="item">
                    <div className="shop1">
                      <Link
                        to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                        <img

                          src={`data:image/jpg;base64,${atob(item.image)}`} alt={item.name} style={{ height: '150px', width: '150px' }}
                          title=""
                          loading="lazy"
                          className="img-fluid"
                          style = {{
                            objectFit: 'contain',
                            borderRadius: 0,
                          }}
                        />
                        <div className=""></div>
                        <div className="shop-content">
                          <h3>{item.name}</h3>
                          <span className="old-price" style={{ fontSize: '24px', color: 'black' }}>
                            <span style={{fontSize:'14px',position: 'relative',
                              top: '-7px',
                              fontSize: '13px',
                              paddingRight: '3px'}}>$</span>{item.price}
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
              ))}
            </OwlCarousel>
          </div>
          <br />
          <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          sx={{marginTop:'50px'}}
          open={showQuantitySnackbar}
          autoHideDuration={3000}
          onClose={() => setShowQuantitySnackbar(false)}>
          <Alert
            onClose={() => setShowQuantitySnackbar(false)}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
          >Oops! It looks like you've reached the maximum quantity for this item.</Alert>
        </Snackbar>
        </>
      )}
    </main>
  );
}
