import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useProductContext } from '../../Contexts/ProductContext';
import { useCartContext } from '../../Contexts/CartContext';
import parse from "html-react-parser";
import { useNavigate } from 'react-router-dom';
import './ProductInfo.css';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slider from 'react-slick';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ReactImageMagnify from 'react-image-magnify';
import Swal from 'sweetalert2';


export default function ProductInfo() {
  const { products } = useProductContext();
  const { addToCart, decreaseQuantity, removeFromCart, increaseQuantity } = useCartContext();
  // const { id } = useParams();
  const detailedDescriptionRef = useRef(null);

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const productIDWithSlug = queryParams.get('product');

  console.log("queryparams =================== ", queryParams);

  console.log("productIDWithSlug ========================= ", productIDWithSlug);

  console.log("location ==================== ", location);

  // const pathParts = location.pathname.split('-');
  // const id = pathParts.pop();
  const id = productIDWithSlug?.split('-').pop();
  // const name = pathParts.join('-');

  // console.log("Product Name:", name);
  // console.log("Product ID:", id);
  // console.log("path parts ==================== ",pathParts);


  const { cartItems } = useCartContext();
  const [additionalImages, setAdditionalImages] = useState([]);
  const [isMagnifyHovered, setIsMagnifyHovered] = useState(false);

  const navigate = useNavigate();
  const [isInCart, setIsInCart] = useState(false);

  const productDetail = products.find((p) => p.id === parseInt(id, 10));
  const productInCart = cartItems.find((item) => item.id === parseInt(id, 10));
  const [isInWishlist, setIsInWishlist] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showRemoveSnackbar, setShowRemoveSnackbar] = useState(false);
  const [showQuantitySnackbar, setShowQuantitySnackbar] = useState(false);

  const [showFullDescription, setShowFullDescription] = useState(false);
  const [descriptionLength, setDescriptionLength] = useState(200);

  const categoryProducts = products.filter(product => product.category_hierarchy[0] === productDetail.category_hierarchy[0]);

  useEffect(() => {
    setIsInCart(!!productInCart);
  }, [productInCart]);

  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const endpoint = '/get_product_images';
  const url = `${apiUrl}${endpoint}`;

  const wishlist_endpoint = '/add_to_wishlist';
  const wishlistUrl = `${apiUrl}${wishlist_endpoint}`;

  const currentUser = JSON.parse(localStorage.getItem('user'));

  console.log("current user ======================= ", currentUser);

  const wishlistCheckUrl = `${apiUrl}/check_wishlist`; // Endpoint to check if the product is in the wishlist
  const removeFromWishlistUrl = `${apiUrl}/remove_from_wishlist`;

  const [mainImage, setMainImage] = useState(null);

  const handleImageSwitch = (newImage) => {
    setMainImage(newImage);
  };

  useEffect(() => {
    if (productDetail && productDetail.image) {
      setMainImage(productDetail.image);
    }
  }, [productDetail]);

  console.log("product in cart ================= ", productInCart);
  console.log("product detail ================= ", productDetail);

  // code for product varients
  const [variants, setVariants] = useState();
  useEffect(() => {
    if (productDetail && Array.isArray(productDetail.variants)) {
      setVariants(productDetail.variants);
    }
  }, [productDetail]);
  console.log("Variant Data: ", variants);
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [selectedVariantId, setSelectedVariantId] = useState(null);

  const handleAttributeChange = (attribute, value) => {
    console.log("attribute: " + attribute + ", value: " + value);
    const newSelectedAttributes = {
      ...selectedAttributes,
      [attribute]: value,
    };

    setSelectedAttributes(newSelectedAttributes);

    // Find the matching variant ID
    const matchingVariant = variants.find((variant) =>
      variant.variant_values.every(
        (variantValue) =>
          newSelectedAttributes[variantValue.attribute] === variantValue.value
      )
    );

    setSelectedVariantId(matchingVariant ? matchingVariant.id : null);
  };
  const uniqueAttributes = Array.isArray(variants) ? variants[0].variant_values.reduce((acc, val) => {
    acc[val.attribute] = true;
    return acc;
  }, {}) : '';

  //code for adding cart products
  const addToCartWithApi = async (productDetail) => {
    try {

      productDetail.variantId = selectedVariantId;

      addToCart(productDetail);

      const currentUser = JSON.parse(localStorage.getItem('user'));

      if (currentUser && currentUser.user) {
        const response = await fetch(`${apiUrl}/add_to_cart`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id, cartItems: productDetail }),
        });

        if (!response.ok) {
          throw new Error('Failed to add product to cart');
        }

      } else {
        console.error('User not logged in'); // Log an error if user is not logged in
      }
    } catch (error) {
      console.error('Error adding product to cart:', error);
    }
  };

  const removeFromCartWithApi = async (productId) => {
    try {
      handleRemoveFromCart(productId);

      const currentUser = JSON.parse(localStorage.getItem('user'));

      if (currentUser && currentUser.user) {
        const response = await fetch(`${apiUrl}/remove_from_cart`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id, productId }),
        });

        if (!response.ok) {
          throw new Error('Failed to remove product from cart');
        }

      } else {
        console.error('User not logged in');
      }
    } catch (error) {
      console.error('Error removing product from cart:', error);
    }
  };

  const handleIncreaseQuantity = async (productId) => {
    const product = products.find(p => p.id === productId);
    const productInCart = cartItems.find(item => item.id === productId);

    // Check if the quantity constraint should be applied
    const isEshopQuantityLimited = product.on_hand_quantity_eshop === "True";
    if (isEshopQuantityLimited) {

      if (productInCart && productInCart.quantity >= product.on_hand_quantity) {
        setShowQuantitySnackbar(true);
        console.error('Cannot increase quantity. Quantity limit reached.');
        return;
      }
    }

    // Increase the quantity in the local state
    increaseQuantity(productId);

    const currentUser = JSON.parse(localStorage.getItem('user'));

    if (currentUser && currentUser.user_id) {
      const response = await fetch(`${apiUrl}/increase_product_qty_in_cart`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: currentUser.user_id, productId }),
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log('Quantity increased:', responseData);
      } else {
        console.error('Failed to increase quantity');
      }
    } else {
      console.error('User not logged in');
    }
  };

  const handleDecreaseQuantityBackend = async (productId) => {
    decreaseQuantity(productId); // Update the quantity in the local state

    const currentUser = JSON.parse(localStorage.getItem('user'));

    // Check if the current user is logged in
    if (currentUser && currentUser.user) {
      try {
        // Send a POST request to the backend to update the quantity
        const response = await fetch(`${apiUrl}/decrease_product_qty_in_cart`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id, productId }),
        });

        if (!response.ok) {
          throw new Error('Failed to decrease quantity');
        }

        const responseData = await response.json();
        console.log(responseData);
      } catch (error) {
        console.error('Error decrease quantity:', error);
      }
    }
  };



  useEffect(() => {
    const checkWishlist = async () => {
      try {
        const currentUserData = localStorage.getItem('user');
        const currentUser = JSON.parse(currentUserData);

        // Check if productDetail exists
        if (productDetail && currentUser && currentUser.user_id) {
          const response = await fetch(wishlistCheckUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user_id: currentUser.user_id, product_detail: productDetail }),
          });

          if (!response.ok) {
            throw new Error('Failed to check wishlist');
          }

          const responseData = await response.json();
          console.log("response data ====================== ", responseData);
          setIsInWishlist(responseData.result.isInWishlist);
        }
      } catch (error) {
        console.error('Error checking wishlist:', error);
      }
    };

    checkWishlist();
  }, [id, productDetail, wishlistCheckUrl]);

  useEffect(() => {
    const postData = async () => {
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id }),
        });

        if (!response.ok) {
          throw new Error('Failed to send POST request');
        }

        const responseData = await response.json();
        const resultData = JSON.parse(responseData.result);

        if (resultData && resultData.images) {
          console.log('Setting additional images:', resultData.images);
          setAdditionalImages(resultData.images);
        } else {
          console.error('Images data not found in response:', responseData);
          setAdditionalImages([])
        }
      } catch (error) {
        console.error('Error sending POST request:', error);
      }
    };

    postData();
  }, [id]);

  useEffect(() => {
    const handleThumbnailClick = (event) => {
      if (!event.target.matches('#thumbnails img')) return;
      const main = document.getElementById('main');
      if (!main) return;
      main.src = event.target.src;
    };

    document.addEventListener('click', handleThumbnailClick);

    return () => {
      document.removeEventListener('click', handleThumbnailClick);
    };
  }, []);

  useEffect(() => {
    if (productDetail && productDetail.description) {
      setDescriptionLength(showFullDescription ? productDetail.description.length : 400);
    }
  }, [productDetail, showFullDescription]);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const renderDescription = () => {
    if (productDetail && productDetail.description) {
      return (
        <>
          <h5 style={{ fontSize: '16px', fontWeight: '700', color: '#000000e3' }}>About this item</h5>
          <p style={{ fontSize: '14px' }}>
            {showFullDescription ? parse(productDetail.description) : parse(productDetail.description.slice(0, descriptionLength) + "...")}
            {productDetail.description.length > 200 && (
              <p className="see-more-btn" onClick={toggleDescription} >
                {showFullDescription ?
                  <p>See Less<svg width="20" height="20" viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-chevron-up"><polyline points="18 15 12 9 6 15" /></svg></p> :
                  <p>See More<svg width="20" height="20" viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9" /></svg></p>}
              </p>
            )}
          </p>
        </>
      );
    } else {
      return null;
    }
  };

  if (!productDetail) {
    return;
  }

  const addToWishlist = async (productDetail) => {
    try {
      const currentUserData = localStorage.getItem('user');
      const currentUser = JSON.parse(currentUserData);

      if (currentUser && currentUser.user_id) {
        const response = await fetch(wishlistUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id, product_detail: productDetail }),
        });
        if (!response.ok) {
          throw new Error('Failed to add to wishlist');
        }
        setIsInWishlist(true);
        setShowSnackbar(true);
      } else {
        console.error('User ID not found in currentUser data');
        Swal.fire({
          // title: "<strong>HTML <u>example</u></strong>",
          // icon: "info",
          html: `
          Hi there! Log in to your account or create one today to enjoy a personalized shopping experience!
          `,
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: `
            Login
          `,
          confirmButtonAriaLabel: "Login",
          cancelButtonText: `
            Register
          `,
          cancelButtonAriaLabel: "Register",
          customClass: {
            confirmButton: 'swal-button-login',
            cancelButton: 'swal-button-register'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/login'; // Redirect to login page
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            window.location.href = '/register'; // Redirect to register page
          }
        });
        // navigate('/login');
      }
    } catch (error) {
      console.error('Error adding to wishlist:', error);
    }
  };

  const removeFromWishlist = async () => {
    try {
      const currentUserData = localStorage.getItem('user');
      const currentUser = JSON.parse(currentUserData);

      if (currentUser && currentUser.user_id) {
        const response = await fetch(removeFromWishlistUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: currentUser.user_id, product_detail: productDetail }),
        });

        if (!response.ok) {
          throw new Error('Failed to remove from wishlist');
        }

        setIsInWishlist(false);
        setShowRemoveSnackbar(true);
      } else {
        console.error('User ID not found in currentUser data');
        navigate('/login');
      }
    } catch (error) {
      console.error('Error removing from wishlist:', error);
    }
  };

  const handleDecreaseQuantity = (productId) => {
    decreaseQuantity(productId);
  };

  // const handleIncreaseQuantity = (productId) => {
  //   increaseQuantity(productId);
  // };

  const handleRemoveFromCart = (productId) => {
    removeFromCart(productId);
  };

  const truncateNameForURL = (name, maxLength) => {
    const words = name.split(' ');
    if (words.length <= maxLength) {
      return words.join('-'); // If the number of words is less than or equal to maxLength, use all words
    }
    return words.slice(0, maxLength).join('-'); // Otherwise, use only the first maxLength words
  };

  const theme = createTheme({
    palette: {
      ochre: {
        main: '#a3423c',
        light: '#E9DB5D',
        dark: '#ffa726',
        contrastText: '#242105',
      },
    },
  });

  const carouselOptions = {
    items: 4,
    loop: false,
    margin: 20,
    nav: true,
    dots: false,
    autoplay: true, // Enable autoplay
    autoplayTimeout: 2000,
    responsive: {
      0: {
        items: 1 // Show only one item in mobile view
      },
      479: {
        items: 2 // Show two items in tablet view
      },
      700: {
        items: 3
      },
      800: {
        items: 4 // Show four items in desktop view
      },
      1093: {
        items: 5
      }
    }
  };

  const handleSeeMoreClick = () => {
    detailedDescriptionRef.current.scrollIntoView({ behavior: 'smooth' });
  };



  return (
    <>
      <ThemeProvider theme={theme}>
        <div className='common-mt'>
          <div className="product-info-container container-fluid">
            <div className='product-info'>
              <div className="row">
                <div className="col-md-6">
                  <div
                    className="magnify-container"
                    onMouseEnter={() => setIsMagnifyHovered(true)}
                    onMouseLeave={() => setIsMagnifyHovered(false)}>
                    <ReactImageMagnify
                      {...{
                        smallImage: {
                          id: 'main',
                          alt: 'Product Image',
                          isFluidWidth: true,
                          src: `data:image/jpeg;base64,${atob(mainImage)}`
                        },
                        largeImage: {
                          src: `data:image/jpeg;base64,${atob(mainImage)}`,
                          width: 1200,
                          height: 1800
                        }
                      }}
                    />
                    {/* <img src={`data:image/jpeg;base64,${atob(productDetail.image)}`} id='main' /> */}
                  </div>
                  {/* <img src={`data:image/jpeg;base64,${atob(productDetail.image)}`} id="main" /> */}
                  <div id="thumbnails">
                    {additionalImages.map((image, index) => (
                      <img
                        key={index}
                        src={`data:image/jpeg;base64,${atob(image.data)}`}
                        loading="lazy"
                        alt={`Thumbnail ${index}`}
                        onClick={() => handleImageSwitch(image.data)}
                      />
                    ))}
                  </div>
                </div>
                <div className={`col-md-6 ${isMagnifyHovered ? 'hide-product-details' : ''}`}>
                  <div className="product-details">
                    <div className="product-header">
                      <h2 className="product-name">{productDetail.name}</h2>
                      {productDetail && productDetail.sub_heading && typeof productDetail.sub_heading === 'string' ? (
                        <p style={{ color: 'blue', fontSize: '12px' }}>{parse(productDetail.sub_heading)}</p>
                      ) : (
                        null
                      )}
                      <p className="product-price"><span>$</span>{productDetail.price}</p>
                      <div style={{ display: "flex" }}>
                        <div>
                          {isInCart ? (
                            <>
                              <div className="quantity-control">
                                <span style={{ paddingRight: '10px' }}>Quantity </span>
                                {productInCart && productInCart.quantity === 1 ? (
                                  <>
                                    <Button variant="outlined" sx={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', borderRadius: '30px' }} onClick={() => removeFromCartWithApi(productDetail.id)}>-</Button>
                                    <span style={{ padding: '7px' }}>{productInCart.quantity}</span>
                                  </>
                                ) : (
                                  <>
                                    <Button variant="outlined" sx={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', borderRadius: '30px' }} onClick={() => handleDecreaseQuantityBackend(productDetail.id)}>-</Button>
                                    <span style={{ padding: '7px' }}> {productInCart ? productInCart.quantity : 0} </span>
                                  </>
                                )}
                                <Button variant="outlined" sx={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', borderRadius: '30px' }} onClick={() => handleIncreaseQuantity(productDetail.id)}>+</Button>
                              </div>
                            </>
                          ) : (
                            <Button variant="contained" sx={{ '& .MuiSvgIcon-root': { marginRight: '8px' } }} onClick={() => addToCartWithApi(productDetail)}>
                              Add to Cart
                            </Button>
                          )}
                          {isInCart && (
                            <Link to={`/cart`}>
                              <br />
                              <Button variant="contained" className="proceed-to-checkout-btn me-2">Proceed to Cart</Button>
                            </Link>
                          )}
                        </div>
                        <div>
                          {!(isInCart) && (
                            // Wishlist part code
                            <div style={{ paddingTop: '5px' }}>
                              {isInWishlist ? (
                                <FavoriteIcon style={{ color: 'red', marginLeft: '7px' }} onClick={removeFromWishlist} />
                              ) : (
                                <FavoriteBorderIcon style={{ color: 'red', marginLeft: '7px' }} onClick={() => addToWishlist(productDetail)} />
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      {Array.isArray(variants) && (
                        <div className="product-variant-group">
                          {Object.keys(uniqueAttributes).map((attribute) => (
                            <div key={attribute} className="product-variant">
                              <b>{attribute}:</b>
                              {attribute === "Color" ? (
                                <div className="colors">


                                  <ul>
                                    {Array.from(
                                      new Set(
                                        variants.map(
                                          (variant) =>
                                            variant.variant_values.find(
                                              (val) =>
                                                val.attribute === attribute
                                            ).value
                                        )
                                      )
                                    ).map((value) => {
                                      const colorValue = variants
                                        .find((variant) =>
                                          variant.variant_values.some(
                                            (val) =>
                                              val.attribute === attribute &&
                                              val.value === value
                                          )
                                        )
                                        .variant_values.find(
                                          (val) =>
                                            val.attribute === attribute &&
                                            val.value === value
                                        ).html_color;
                                      return (
                                        <li
                                          key={value}
                                          data-color={colorValue}
                                          style={{
                                            backgroundColor: colorValue,
                                            outline:
                                              selectedAttributes[attribute] ===
                                                value
                                                ? `2px solid #1976d2`
                                                : "2px solid #ddd",
                                          }}
                                          onClick={() =>
                                            handleAttributeChange(
                                              attribute,
                                              value
                                            )
                                          }
                                        />
                                      );
                                    })}
                                  </ul>
                                </div>
                              ) : (
                                Array.from(
                                  new Set(
                                    variants.map(
                                      (variant) =>
                                        variant.variant_values.find(
                                          (val) => val.attribute === attribute
                                        ).value
                                    )
                                  )
                                ).map((value) => (
                                  <button
                                    key={value}
                                    className="product-variant-select-btn"
                                    onClick={() =>
                                      handleAttributeChange(attribute, value)
                                    }
                                    style={{
                                      backgroundColor:
                                        selectedAttributes[attribute] === value
                                          ? "yellow"
                                          : "white",
                                    }}
                                  >
                                    {value}
                                  </button>
                                ))
                              )}
                            </div>
                          ))}
                          {selectedVariantId && (
                            <p>Selected Variant ID: {selectedVariantId}</p>
                          )}
                        </div>
                      )}
                    </div>
                    {/* <div className="product-description">
                      <h5 style={{ fontSize: '16px', fontWeight: '700', color: '#000000e3' }}>About this item</h5>
                      <p style={{ fontSize: '14px' }}>{parse(productDetail.description)}</p>
                    </div> */}
                    <br />
                    {renderDescription()}
                    {/* <br /> */}
                    {productDetail.detailed_description !== false ? (
                      <p className="see-more-btn" onClick={handleSeeMoreClick}>See more product details</p>
                    ) : null}

                  </div>
                </div>
              </div>
            </div>
            <div >
              <h3 style={{ textAlign: 'center' }}>Related products</h3>
              <br />
              <OwlCarousel className="owl-carousel3 owl-theme" {...carouselOptions}>
                {categoryProducts.map((item, index) => (
                  <div key={index} className="item">
                    <div className="shop1">
                      <Link
                        to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                        <img

                          src={`data:image/jpg;base64,${atob(item.image)}`} alt={item.name} style={{ height: '150px', width: '150px' }}
                          title=""
                          loading="lazy"
                          className="img-fluid"
                          style={{
                            objectFit: 'contain',
                            borderRadius: 0,
                          }}
                        />
                        <div className=""></div>
                        <div className="shop-content">
                          <h3>{item.name}</h3>
                          <span className="old-price" style={{ fontSize: '24px', color: 'black' }}>
                            <span style={{
                              position: 'relative',
                              top: '-7px',
                              fontSize: '13px',
                              paddingRight: '3px'
                            }}>$</span>{item.price}
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
              <br />
              <br />
              {productDetail.detailed_description !== false ? (
                <div ref={detailedDescriptionRef}>
                  <h3 style={{ textAlign: 'center' }}>More details</h3>
                  {parse(productDetail.detailed_description)}
                </div>
              ) : null}
            </div>
            <br></br>
          </div >
        </div >



        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={showSnackbar}
          autoHideDuration={1000}
          onClose={() => setShowSnackbar(false)}
          message="Added to Wishlist"
        />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={showRemoveSnackbar}
          autoHideDuration={1000}
          onClose={() => setShowRemoveSnackbar(false)}
          message="Removed from Wishlist"
        />

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          sx={{ marginTop: '50px' }}
          open={showQuantitySnackbar}
          autoHideDuration={3000}
          onClose={() => setShowQuantitySnackbar(false)}>
          <Alert
            onClose={() => setShowQuantitySnackbar(false)}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
          >Oops! It looks like you've reached the maximum quantity for this item. </Alert>
        </Snackbar>

      </ThemeProvider >
    </>
  );
}
