import React, { useState, useEffect } from 'react';
import { useProductContext } from '../../Contexts/ProductContext';
import CategoryCarousel from '../CategoryCarousel/CategoryCarousel';
import TrendingItemsCarousel from '../Trending/Trending';
import { Carousel2 } from '../Carousel2/Carousel2';
import { Banner } from '../Banner/Banner';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CategoryCarouselMobile from '../CategoryCarouselMobile/CategoryCarouselMobile';
import HomePageProductSlider from '../Carousel/Carousel';
import { HomePageExtend } from '../../Apps/HomePageExtend';

const Homepage = () => {
  const { products, loading } = useProductContext();
  // const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [hasReloaded, setHasReloaded] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 553);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!loading && products.length > 0) {
      setDataLoaded(true);
    }
  }, [loading, products]);

  useEffect(() => {
    if (!hasReloaded) {
      setHasReloaded(true);
    }
  }, [hasReloaded]);


  var abc;
  if (products && products.length > 0) {
    const bannerObjValDot = products[0];
    console.log(bannerObjValDot)
    abc = bannerObjValDot['bannerObjVal'];

  }

  return (
    <div className="homepage-container" style={{ minHeight: '1000px', position: 'relative' }}>
      {/* <CategoryCarouselMobile/> */}
      {abc ? (
        <Banner />
      ) : (
        <HomePageProductSlider />
      )}
      {!dataLoaded && (
        <div className='loader' style={{ position: 'absolute', top: '30%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9999' }}>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress size={60} />
          </Box>
          {/* <p>Loading</p> */}
        </div>
      )}
      <br />
      {dataLoaded && (
        <>
          {isMobileView ? (
            <CategoryCarouselMobile />
          ) : (
            <CategoryCarousel />
          )}
          <br />
          <br />
          <Carousel2 />
          <TrendingItemsCarousel />
          {/* <Benefits/> */}
          {/* <HomePageExtend customProp="kawal Value"/> */}
        </>
      )}
    </div>
  );
}

export default Homepage;
