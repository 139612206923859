import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Banner.css';

export function Banner() {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const endpoint = '/get_banners';
  const bannerUrl = `${apiUrl}${endpoint}`;

  const [bannerData, setBannerData] = useState([]);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(bannerUrl, {
          method: 'GET',
        });
        const data = await response.json();
        setBannerData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1046);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [bannerUrl]);

  return (
    <>
      <div className='common-mt'>
        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            {bannerData.map((promo, index) => (
              <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''} banner-height`}>
                <Link to={{
                  pathname: '/bannerproducts',
                  search: `?productIds=${promo.product_ids.join(',')}` // Pass product_ids as state 
                }}
                  className="image-link">
                  <img
                    src={isMobileView ? `data:image/jpeg;base64,${atob(promo.image_mobile)}` : `data:image/jpeg;base64,${atob(promo.image_base64)}`}
                    fetchpriority="high"
                    loading='eager'
                    className="img-fluid w-100"
                    alt={`slider-img-${index}`} />
                </Link>
              </div>
            ))}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </>
  );
}
