import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import './Checkout.css';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Snackbar, Alert, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import StepBar from '../StepBar/StepBar';

const apiUrl = process.env.REACT_APP_API_BASE_URL;
const getRateEndpoint = '/get_rate';
const createCheckoutEndpoint = '/create-checkout-session';
const url = `${apiUrl}${getRateEndpoint}`;
const checkouturl = `${apiUrl}${createCheckoutEndpoint}`;


export const Checkout = () => {
  const [addresses, setAddresses] = useState([]);
  const [address, setAddress] = useState({
    street: '',
    city: '',
    country: '',
    state: '',
    zip: ''
  });
  const [open, setOpen] = useState(false);
  const [newAddress, setNewAddress] = useState({
    street: '',
    city: '',
    pincode: '',
    country: '',
    state: ''
  });
  const [errors, setErrors] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { state } = useLocation();
  const filteredProducts = state.filteredProducts;
  const { cartItems = [], totalAmount = 0, additionalData = {} } = state || {};
  const [rate, setRate] = useState(null);
  const isLoggedIn = localStorage.getItem('user');

  const validateFields = () => {
    let tempErrors = {};
    if (!newAddress.street) tempErrors.street = "Street is required";
    if (!newAddress.city) tempErrors.city = "City is required";
    if (!newAddress.pincode) tempErrors.pincode = "Pincode is required";
    if (!newAddress.country) tempErrors.country = "Country is required";
    if (!newAddress.state) tempErrors.state = "State is required";
    return tempErrors;
  };

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const deleteAddress = async (addressId) => {
    try {
      const response = await fetch(`${apiUrl}/delete_address`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: addressId, user: isLoggedIn })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      window.location.reload();
    } catch (error) {
      console.error("Failed to delete address:", error);
    }
  }

  useEffect(() => {
    const listAddresses = async () => {
      const listAddressesurl = apiUrl + '/list_addresses';
      try {
        const response = await fetch(listAddressesurl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user: isLoggedIn })
        });

        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.status);
        }

        const addresses = await response.json();
        setAddresses(addresses.result.addresses);
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    };

    listAddresses();
  }, []);

  const submitNewAddress = async () => {
    if (addresses.length >= 5) {
      handleSnackbarOpen("Cannot add more addresses. Please delete an existing address.");
      return;
    }

    const tempErrors = validateFields();
    if (Object.keys(tempErrors).length > 0) {
      setErrors(tempErrors);
      return;
    }

    try {
      const response = await fetch(apiUrl + '/add_address', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ newAddress: newAddress, user: isLoggedIn })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      handleClose();
      window.location.reload();
    } catch (error) {
      console.error("Failed to add address:", error);
    }
  };

  const handleOpen = (event) => {
    event.preventDefault();
    if (addresses.length >= 5) {
      handleSnackbarOpen("Cannot add more addresses. Please delete an existing address.");
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setErrors({});
  };

  const handleChange = (e) => {
    setNewAddress({
      ...newAddress,
      [e.target.name]: e.target.value
    });
    if (errors[e.target.name]) {
      setErrors({
        ...errors,
        [e.target.name]: null
      });
    }
  };

  const handleSelectAddress = (selectedAddress) => {
    setAddress(selectedAddress);
  };

  const handlePincodeChange = (event) => {
    const { value, name } = event.target;
    // Regular expression to match numbers and specific characters such as hyphen and space
    const cleanedValue = value.replace(/[^0-9-\s]+/g, ''); // Adjust regex as needed for your use case
    setNewAddress(prev => ({
      ...prev,
      [name]: cleanedValue
    }));
  
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null
      });
    }
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (address.street !== '') {
  //       try {
  //         const body = {
  //           products: cartItems,
  //           quotation_details: {
  //             order_id: additionalData.result.order_id,
  //             code: additionalData.result.order_name,
  //             address: address
  //           }
  //         };

  //         console.log("additional data ============ ",additionalData);
  //         const response = await fetch(url, {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //           },
  //           body: JSON.stringify(body),
  //         });

  //         if (!response.ok) {
  //           throw new Error('Failed to fetch rate');
  //         }

  //         const responseData = await response.json();
  //         console.log("response data ============= ",responseData);
  //         const resultData = JSON.parse(responseData.result);
  //         setRate(resultData.rate);
  //       } catch (error) {
  //         console.error('Error fetching rate:', error);
  //       }
  //     }
  //   };

  //   fetchData();
  // }, [address]);

  

  const makePayment = async () => {
    const stripe = await loadStripe("pk_test_51OuYkrSIvB0UKK5tB4dxItDOCe7nI1P9vVg4oqn1UGLRu7b7Ol7wJ3dxgwM2JZCmapzxNiHX0mzalPQ7hNCf2RWR00VMHeISdt");
    const body = {
      products: cartItems,
      quotation_details: {
        orders: additionalData.result
        // order_id: additionalData.result.order_id,
        // code: additionalData.result.order_name,
      },
      address: address,
      rate: rate,
      user: JSON.parse(isLoggedIn),
    }

    console.log("additional_data =============== ",additionalData);

    const headers = {
      'Content-Type': 'application/json',
    }

    const response = await fetch(checkouturl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body)
    });

    const session = await response.json();
    const sessionIDFinal = session.result.session_id

    const result = await stripe.redirectToCheckout({
      sessionId: sessionIDFinal
    });
  };

  const carouselOptions = {
    items: 4,
    loop: false,
    margin: 20,
    nav: true,
    dots: false,
    autoplay: true, // Enable autoplay
    autoplayTimeout: 2000,
    responsive: {
      0: {
        items: 1 // Show only one item in mobile view
      },
      479: {
        items: 2 // Show two items in tablet view
      },
      700: {
        items: 3
      },
      800: {
        items: 4 // Show four items in desktop view
      },
      1093: {
        items: 5
      }
    }
  };

  const truncateNameForURL = (name, maxLength) => {
    const words = name.split(' ');
    if (words.length <= maxLength) {
      return words.join('-'); // If the number of words is less than or equal to maxLength, use all words
    }
    return words.slice(0, maxLength).join('-'); // Otherwise, use only the first maxLength words
  };


  return (
    <main>
      <div className="dlvr_adds" style={{ 'marginTop': '100px' }}>
        <div className="container-fluid">
        <StepBar/>
          <div className="row">
            <div className="col-lg-8">
              <div className="dlvr_cont">
                <h2>Select Shipping Address</h2>
                <form className="shipp_address">
                  {addresses.map((address, index) => (
                    <div className="form-group" key={index}>
                      <div>
                        <input
                          type="radio"
                          name="accent-group"
                          className="accent-black black accent-color"
                          id={`accent-black${index}`}
                          onChange={() => handleSelectAddress(address)}
                          style={{ display: 'inline' }}
                        />
                        <label htmlFor={`accent-black${index}`} style={{ display: 'inline' }}>
                          {address.street}, {address.city}, {address.zip}, {address.country}
                        </label>
                      </div>
                      <div>
                        <Button variant='outlined' color='error' size='small'
                          onClick={() => deleteAddress(address.id)}
                          sx={{ marginTop: '5px', height:'24px' }}>Delete</Button>
                      </div>
                    </div>
                  ))}
                  <div className="form__confirmation">
                    <Button variant='contained' size='small' onClick={handleOpen}>+ Add New Address</Button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ship-cart">
                <div className="ship-cart-pr">
                  <h3>Summary</h3>
                  <ul>
                    <li>
                      <label>Subtotal</label>
                      <div className="value pull-right">${totalAmount.toFixed(2)}</div>
                    </li>
                    <li>
                      <label>Delivery Fee</label>
                      <div className="value pull-right">${rate ? rate.toFixed(2) : '00.00'}</div>
                    </li>
                    <li className="hr-border"></li>
                    <li>
                      <label>Total</label>
                      <div className="value pull-right" style={{ fontSize: 24, color: "#0989ff" }}>
                        ${(totalAmount + (rate ? rate : 0)).toFixed(2)}
                      </div>
                    </li>
                  </ul>
                  <div className="ship-cart-shop">
                      {/* <Button variant='contained' size='medium' onClick={makePayment} disabled={!rate} 
                      sx={{ width: '100%' }}>
                        Proceed to Payment</Button> */}
                      <Button variant='contained' size='medium' onClick={makePayment}
                      sx={{ width: '100%' }}>
                        Proceed to Payment</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add New Address</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="street"
            name="street"
            label="Street"
            type="text"
            fullWidth
            required
            value={newAddress.street}
            onChange={handleChange}
            error={!!errors.street}
            helperText={errors.street}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="city"
                name="city"
                label="City"
                type="text"
                fullWidth
                required
                value={newAddress.city}
                onChange={handleChange}
                error={!!errors.city}
                helperText={errors.city}
                sx={{ marginBottom: '15px' }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="pincode"
                name="pincode"
                label="Zipcode"
                type="text"
                fullWidth
                required
                value={newAddress.pincode}
                onChange={handlePincodeChange}
                error={!!errors.pincode}
                helperText={errors.pincode}
                sx={{ marginBottom: '15px' }}
              />
            </Grid>
          </Grid>
          <RegionDropdown
            country={newAddress.country}
            value={newAddress.state}
            onChange={(val) => setNewAddress({ ...newAddress, state: val })}
            classes="form-control"
            blankOptionLabel="Select State"
            style={{
              borderColor: errors.state ? 'red' : undefined, height: '56px',
              padding: '10px 14px', borderRadius: '4px'
            }}
          />
          {errors.state && <p style={{
            color: '#d32f2f', fontSize: '0.75rem', marginLeft: '14px', fontWeight: '400',
            marginTop: '3px'
          }}>{errors.state}</p>}
          <CountryDropdown
            value={newAddress.country}
            onChange={(val) => setNewAddress({ ...newAddress, country: val })}
            classes="form-control"
            style={{
              borderColor: errors.country ? 'red' : undefined, height: '56px',
              padding: '10px 14px', borderRadius: '4px', marginTop: '15px'
            }}
          />
          {errors.country && <p style={{
            color: '#d32f2f', fontSize: '0.75rem', marginLeft: '14px', fontWeight: '400',
            marginTop: '3px'
          }}>{errors.country}</p>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={submitNewAddress} color="primary">Add Address</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <div>
            <br/>
            <h3 style={{ textAlign: 'center' }}>Related products</h3>
            <br/>
            <OwlCarousel {...carouselOptions}>
              {filteredProducts.map((item, index) => (
                <div key={index} className="item">
                    <div className="shop1">
                      <Link
                        to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                        <img

                          src={`data:image/jpg;base64,${atob(item.image)}`} alt={item.name} style={{ height: '150px', width: '150px' }}
                          title=""
                          loading="lazy"
                          className="img-fluid"
                          style = {{
                            objectFit: 'contain',
                            borderRadius: 0,
                          }}
                        />
                        <div className=""></div>
                        <div className="shop-content">
                          <h3>{item.name}</h3>
                          <span className="old-price" style={{ fontSize: '24px', color: 'black' }}>
                            <span style={{fontSize:'14px',position: 'relative',
                              top: '-7px',
                              fontSize: '13px',
                              paddingRight: '3px'}}>$</span>{item.price}
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
              ))}
            </OwlCarousel>
          </div>
    </main>
  );
};

export default Checkout;
