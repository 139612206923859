import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useCompanyContext } from '../../Contexts/CompanyContext';
import logoblue from './eShop-LOGO-Blue.png'
import './Footer.css';

export default function Footer() {
  // eslint-disable-next-line no-unused-vars
  const [userName, setUserName] = useState('');
  const companyID = localStorage.getItem('companyID');
  const { companyDetails } = useCompanyContext();
  console.log("company dteails footer ================",companyDetails);

  const logoSrc = companyDetails ? `data:image/png;base64,${atob(companyDetails.company_image)}` : '';
  const address = companyDetails ? companyDetails.company_address : {};
  const companyName = companyDetails ? companyDetails.company_name : 'My Company';
  const companyPhone = companyDetails ? companyDetails.company_phone : '1234567890';
  const companyEmail = companyDetails ? companyDetails.company_email : 'info@yourcompany.com';

  const addressLine1 = address.street ? `${address.street}${address.street2 ? ', ' + address.street2 : ''}` : '';
  const addressLine2 = address.city ? `${address.city}${address.state ? ', ' + address.state : ''}${address.zip ? ' ' + address.zip : ''}` : '';
  const fullAddress = `${addressLine1}, ${addressLine2}, ${address.country || ''}`;

  useEffect(() => {
    // Retrieve user information from localStorage
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const { user } = JSON.parse(storedUser);
      setUserName(user);
    }
  }, []);

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="footer-content">
              {/* <h2>About Us</h2> */}
              <div className="logo">{logoSrc ? <img src={logoSrc} className='header-logo' alt="Company Logo"
                    style={{height:'1%',width:'65px'}} /> : <span>No Logo</span>}</div>
              <div className="map bold" style={{ fontSize: 12 }}>
                <i
                  className="fas fa-map-marker-alt mr-2"
                  style={{ marginRight: 5, color: "#009aff" }}
                />
                <br />
                <b>Store Address</b>
              </div>
              <div className="a">{fullAddress}</div>
              <div className="a">{companyPhone}</div>
              <div className="a">{companyEmail}</div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="footer-content">
              <h2>INFOMATION</h2>
              <ul>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                {/* <li>
                  <a href="">Brands</a>
                </li>
                <li>
                  <a href="">Gift Vouchers</a>
                </li>
                <li>
                  <a href="">Site Map</a>
                </li>
                <li>
                  <a href="">Accessories</a>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="col-md-2">
            <div className="footer-content">
              <h2>MY ACCOUNT</h2>
              <ul>
                {/* <li>
                  <a href="">My Account</a>
                </li>
                <li>
                  <a href="">Order Tracking</a>
                </li> */}
                <li>
                  <Link to="/wishlist">Wishlist</Link>
                </li>
                <li>
                  <Link to="/cart">Cart</Link>
                </li>
                {/* <li>
                  <a href="">Checkout</a>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="col-md-2">
            <div className="footer-content">
              {/* <h2>CATEGORIES</h2>
              <ul>
                <li>
                  <a href="">Mobile</a>
                </li>
                <li>
                  <a href="">Headphone</a>
                </li>
                <li>
                  <a href="">Laptop</a>
                </li>
                <li>
                  <a href="">New Arrivals</a>
                </li>
                <li>
                  <a href="">All Categories</a>
                </li>
              </ul> */}
            </div>
          </div>
          <div className="col-md-3">
            <div className="footer-content">
              <h2>SUBSCRIBE TO OUR NEWSLETTER</h2>
              <p>
                Please enter email address to create or update your profile and
                manage your subscriptions:
              </p>
              <form method="" className="relative d-flex align-items-center">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Your email*"
                  className="form-control input-newletter"
                  required=""
                />
                <span className="input-group-btn" style={{ marginBottom: '21px' }}>
                  <input
                    className="btn btn-newsletter"
                    type="submit"
                    defaultValue="Sign Up"
                    style={{ color: 'white' }}
                  />
                </span>
              </form>
              {/*<div className="social-links">
                <a href="#">
                  <i className="fab fa-facebook-f" />
                </a>
                <a href="#">
                  <i className="fab fa-twitter" />
                </a>
                <a href="#">
                  <i className="fab fa-linkedin-in" />
                </a>
                <a href="#">
                  <i className="fab fa-instagram" />
                </a>
              </div>*/}
            </div>
          </div>
        </div>
      </div>
    </footer>


  );
};
