import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { useCartContext } from '../../Contexts/CartContext';
import { useCompanyContext } from '../../Contexts/CompanyContext';
import userdp from './icons8-user-30 (1).png'
import './Navbar.css';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import logonew from './eShop-LOGO-Blue.png';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ThemeProvider, createTheme } from '@mui/material/styles';


export default function Navbar() {
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();
  const [isCardVisible, setIsCardVisible] = useState(false);
  const { cartItems, clearCart } = useCartContext();
  // const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const { companyDetails } = useCompanyContext();
  console.log("company details navbarrrrrrrrr ",companyDetails);
  // const companyId = companyDetails ?  companyDetails.company_id : '';
  // const dynamicURL = `/home/${companyId}`;
  const logoSrc = companyDetails ? `data:image/png;base64,${atob(companyDetails.company_image)}` : '';
  const companyName = localStorage.getItem('companyName');
  const dynamicURL = companyName ? `/home/${companyName}` : '/';
  console.log("logo src ================== ",logoSrc);
  

  const handleLogout = async () => {
    try {
      // await synchronizeCartWithBackend();
      localStorage.removeItem('user');
      setUserName('');

      clearCart();

      navigate('/login');

      window.location.reload();
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  // const synchronizeCartWithBackend = async () => {
  //   try {
  //     const isLoggedIn = localStorage.getItem('user');
  //     const currentUser = JSON.parse(isLoggedIn);
  //     if (isLoggedIn) {
  //       const cartItems = JSON.parse(localStorage.getItem('cartItems'));

  //       const response = await fetch(`${apiUrl}/save_to_cart`, {
  //         method: 'POST',
  //         headers: { 'Content-Type': 'application/json' },
  //         body: JSON.stringify({ user_id: currentUser.user_id, cartItems:cartItems }),
  //       });

  //       if (!response.ok) {
  //         console.error('Failed to synchronize cart with backend');
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error synchronizing cart with backend:', error);
  //   }
  // };

  useEffect(() => {
    // Retrieve user information from localStorage
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const { user } = JSON.parse(storedUser);
      setUserName(user);
    }
  }, []); // Empty dependency array ensures this effect runs only once, on component mount

  const toggleDisplayCard = () => {
    setIsCardVisible(!isCardVisible); // Toggle the visibility state of the display card
  };

  useEffect(() => {
    // Script to handle menu functionality
    const menu = document.querySelector(".menu");
    const menuMain = menu.querySelector(".menu-main");
    const goBack = menu.querySelector(".go-back");
    const menuTrigger = document.querySelector(".mobile-menu-trigger");
    const closeMenu = menu.querySelector(".mobile-menu-close");
    let subMenu;

    const handleMenuItemClick = (e) => {
      if (!menu.classList.contains("active")) {
        return;
      }
      if (e.target.closest(".menu-item-has-children")) {
        const hasChildren = e.target.closest(".menu-item-has-children");
        showSubMenu(hasChildren);
      } else {
        // Close the menu when a menu item is clicked
        toggleMenu();
      }
    };

    const handleGoBackClick = () => {
      hideSubMenu();
    };

    const handleMenuTriggerClick = () => {
      toggleMenu();
    };

    const handleCloseMenuClick = () => {
      toggleMenu();
    };

    const handleOverlayClick = () => {
      toggleMenu();
    };

    const toggleMenu = () => {
      menu.classList.toggle("active");
      document.querySelector(".menu-overlay").classList.toggle("active");
    };

    const showSubMenu = (hasChildren) => {
      subMenu = hasChildren.querySelector(".sub-menu");
      subMenu.classList.add("active");
      subMenu.style.animation = "slideLeft 0.5s ease forwards";
      const menuTitle = hasChildren.querySelector("i").parentNode.childNodes[0].textContent;
      menu.querySelector(".current-menu-title").innerHTML = menuTitle;
      menu.querySelector(".mobile-menu-head").classList.add("active");
    };

    const hideSubMenu = () => {
      subMenu.style.animation = "slideRight 0.5s ease forwards";
      setTimeout(() => {
        subMenu.classList.remove("active");
      }, 300);
      menu.querySelector(".current-menu-title").innerHTML = "";
      menu.querySelector(".mobile-menu-head").classList.remove("active");
    };

    window.onresize = () => {
      if (window.innerWidth > 991) {
        if (menu.classList.contains("active")) {
          toggleMenu();
        }
      }
    };

    menuMain.addEventListener("click", handleMenuItemClick);
    goBack.addEventListener("click", handleGoBackClick);
    menuTrigger.addEventListener("click", handleMenuTriggerClick);
    closeMenu.addEventListener("click", handleCloseMenuClick);
    document.querySelector(".menu-overlay").addEventListener("click", handleOverlayClick);

    // Cleanup
    return () => {
      menuMain.removeEventListener("click", handleMenuItemClick);
      goBack.removeEventListener("click", handleGoBackClick);
      menuTrigger.removeEventListener("click", handleMenuTriggerClick);
      closeMenu.removeEventListener("click", handleCloseMenuClick);
      // document.querySelector(".menu-overlay").removeEventListener("click", handleOverlayClick);
    };
  }, []);

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });


  return (
    <ThemeProvider theme={darkTheme}>
      <header className="header" style={{ backgroundColor: '#000000' }}>
        <div className="container-fluid">
          <div className="row v-center">
            <div className="header-item item-left">
              <div className="logo">
                <Link to="/">
                    {/* <img src={logonew} alt="Default Logo" className='header-logo' /> */}
                    {logoSrc ? <img src={logoSrc} className='header-logo' alt="Company Logo"
                    style={{height:'1%',width:'65px'}} /> : <span>No Logo</span>}
                </Link>
              </div>
            </div>
            {/* menu start here */}
            <div className="header-item item-center">
              <div className="menu-overlay"></div>
              <nav className="menu">
                <div className="mobile-menu-head">
                  <div className="go-back">
                    <i className="fa fa-angle-left" />
                  </div>
                  <div className="current-menu-title" />
                  <div className="mobile-menu-close">×</div>
                </div>
                <ul className="menu-main">
                  <li>
                    <Link to="/">Shop</Link>
                  </li>
                  <li>
                    <Link to="/myorders">My Orders</Link>
                  </li>
                  <li>
                    <Link to="/wishlist">My Wishlist</Link>
                  </li>
                </ul>
              </nav>
            </div>
            {/* menu end here */}
            <style
              dangerouslySetInnerHTML={{
                __html:
                  "\n        .cart-btn{\n          line-height: 10px;\n          background: red;\n          padding: 10px;\n          margin: 0 10px;\n          border-radius: 24px\n        }\n      "
              }}
            />
            <div className="header-item item-right">
              <div className='cart-icon'>
                <Link to="/cart" className="text-white">
                  <Badge badgeContent={cartItems.length} color="primary">
                    <ShoppingCartIcon width='20px' height='20px'></ShoppingCartIcon>
                    {/* <img src="/img/icons8-cart-64.png" width='20px' height='20px' alt="Cart"></img> */}
                  </Badge>
                </Link>
              </div>
              {userName ? (
                <>
                  {/* Display card */}
                  {/* Display picture and user name */}
                  <div className="user-info" onClick={toggleDisplayCard}>
                    <div className="display-picture">
                      <img src={userdp} alt="" />
                    </div>
                    <span className="user-name">{userName}</span>
                  </div>
                  <Menu
                    anchorEl={isCardVisible ? document.querySelector('.user-info') : null}
                    open={isCardVisible}
                    onClose={() => setIsCardVisible(false)}
                  >
                    <MenuItem onClick={() => console.log("Account clicked")}>Account</MenuItem>
                    <MenuItem onClick={() => console.log("Settings clicked")}>Settings</MenuItem>
                    <MenuItem onClick={handleLogout}>Log Out</MenuItem>
                  </Menu>
                </>
              ) : (
                <Link to="/login">
                  <button className='signin-btn'>Log In</button>
                </Link>
              )}

              {/* mobile menu trigger */}
              <div className="mobile-menu-trigger">
                <span />
              </div>
              {/* Profile */}
              <style
              // dangerouslySetInnerHTML={{
              //   __html:
              //     "\n        \t\n\t\t.display-picture{\n\t\t  margin-left: auto;\n\t\t}\n\t\t.display-picture img{\n\t\t  width: 50px;\n\t\t  border-radius: 50%;\n\t\t  border:2px solid #fff;\n\t\t}\n\t\t.display-picture img:hover{\n\t\tborder:2px solid #E78341;\n\t\t}\n\t\t.display-p-card{\n\t\t  transition: 0.5s ease;\n\t\t}\n\n\t\t.display-p-card ul{\n\t\t  display: flex;\n\t\t  align-items: flex-start;\n\t\t  flex-direction: column;\n\t\t  background: #ffffff;\n\t\t  position: absolute;\n\t\t  top: 4rem;\n\t\t  right:0rem;\n\t\tborder-radius: 2px;\n\t\tpadding: 10px 50px 10px 20px;\n\t\t}\n\t\t.display-p-card ul li{\n\t\t \n\t\t  padding: 5px 0;\n\t\t  color: #FFF;\n\t\t  font-size: 14px;\n\t\t}\n\t\t.hidden{\n\t\t  display: none;\n\t\t}\n\t\t.header .row>* {\n    width: auto;\n}\n        "
              // }}
              />
            </div>
          </div>
        </div>
      </header>
    </ThemeProvider>

  );
};
