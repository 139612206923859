import React, { createContext, useContext, useState, useEffect } from 'react';

const CompanyContext = createContext();

export const useCompanyContext = () => useContext(CompanyContext);

export const CompanyProvider = ({ children }) => {
    const [companyDetails, setCompanyDetails] = useState(null);
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    
    // Retrieve companyID from localStorage
    const companyID = localStorage.getItem('companyID');

    useEffect(() => {
        // Extract the subdomain (company name) from the hostname
        const hostname = window.location.hostname; // e.g., "www.hondashop.digitaldukanworld.com"
        const companyName = hostname.split('.')[0].replace('www', ''); // Extract "hondashop"
        console.log("company name ===================== ",companyName);
        
        // Set companyName in localStorage (if needed)
        if (companyName) {
            localStorage.setItem('companyName', companyName);

            const endpoint = `/get/company_details?companyName=${encodeURIComponent(companyName)}`;
            const getCompanyUrl = `${apiUrl}${endpoint}`;

            const getCompanyDetails = async () => {
                try {
                    const response = await fetch(getCompanyUrl, {
                        method: 'GET',
                    });

                    const data = await response.json();
                    console.log("data(company context ====================== )", data);
                    if (data.success && data.company_details) {
                        setCompanyDetails(data.company_details);
                    } else {
                        console.log("ERROR Fetching company details.....");
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };

            getCompanyDetails();
        }
    }, []);

    // // Only update companyID if on the home page
    // useEffect(() => {
    //     const path = window.location.pathname;
    //     const extractedCompanyID = path.split('/').pop();

    //     // Set companyID in localStorage if it's a home page
    //     if (path.startsWith('/home/') && extractedCompanyID) {
    //         localStorage.setItem('companyID', extractedCompanyID);
    //     }
    // }, []);

    console.log("company details(company context) =================== ", companyDetails);

    return (
        <CompanyContext.Provider value={{ companyDetails }}>
            {children}
        </CompanyContext.Provider>
    );
};
