import React, { useState, useEffect } from 'react';
import { useProductContext } from '../../Contexts/ProductContext';
import { useCartContext } from '../../Contexts/CartContext';
import { Link } from 'react-router-dom';
import './Wishlist.css';
import { useNavigate } from 'react-router-dom';
import delIcon from './delete_6861294.png';
import emptywishlist from './emptywishlist.png';
import Button from '@mui/material/Button';

export const Wishlist = () => {
    const loggedinUser = localStorage.getItem('user');
    const [wishlistItems, setWishlistItems] = useState([]);
    const [wishlistProducts, setWishlistProducts] = useState([]);
    const user = JSON.parse(localStorage.getItem('user'));
    const { products } = useProductContext();
    const { addToCart } = useCartContext();
    const { cartItems } = useCartContext();
    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const removeFromWishlistUrl = `${apiUrl}/remove_from_wishlist`;

    useEffect(() => {
        if (user) {
            const apiUrl = process.env.REACT_APP_API_BASE_URL;
            const endpoint = '/fetch_wishlist';
            const url = `${apiUrl}${endpoint}`;

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user })
            };
            fetch(url, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setWishlistItems(data.result.wishlist);
                })
                .catch(error => {
                    console.error('Error fetching wishlist:', error);
                });
        }
    }, []);

    useEffect(() => {
        if (wishlistItems.length > 0 && products.length > 0) {
            const filteredProducts = products.filter(product => {
                return wishlistItems.includes(product.name);
            });
            setWishlistProducts(filteredProducts);
        }
    }, [wishlistItems, products]);

    const isInCart = (productId) => {
        return cartItems.some(item => item.id === productId);
    };

    const addToCartWithApi = async (productDetail) => {
        try {
    
          addToCart(productDetail);
    
          const currentUser = JSON.parse(localStorage.getItem('user'));
    
          if (currentUser && currentUser.user) {
            const response = await fetch(`${apiUrl}/add_to_cart`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ user_id: currentUser.user_id, cartItems: productDetail }),
            });
    
            if (!response.ok) {
              throw new Error('Failed to add product to cart');
            }
    
          } else {
            console.error('User not logged in'); // Log an error if user is not logged in
          }
        } catch (error) {
          console.error('Error adding product to cart:', error);
        }
      };

    const handleAddToCart = async (product) => {
        addToCartWithApi(product);

        try {
            const currentUserData = localStorage.getItem('user');
            const currentUser = JSON.parse(currentUserData);

            if (currentUser && currentUser.user_id) {
                const response = await fetch(removeFromWishlistUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user_id: currentUser.user_id, product_detail: product }),
                });

                if (!response.ok) {
                    throw new Error('Failed to remove from wishlist');
                }

                // If successful, update the wishlist state
                const updatedWishlistItems = wishlistItems.filter(item => item.id !== product.id);
                setWishlistItems(updatedWishlistItems);
                window.location.reload();
            } else {
                console.error('User ID not found in currentUser data');
                navigate('/login');
            }
        } catch (error) {
            console.error('Error removing from wishlist:', error);
        }
    };

    const handleRemoveFromWishlist = async (product) => {
        try {
            const currentUserData = localStorage.getItem('user');
            const currentUser = JSON.parse(currentUserData);

            if (currentUser && currentUser.user_id) {
                const response = await fetch(removeFromWishlistUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user_id: currentUser.user_id, product_detail: product }),
                });

                if (!response.ok) {
                    throw new Error('Failed to remove from wishlist');
                }

                // If successful, update the wishlist state
                const updatedWishlistItems = wishlistItems.filter(item => item.id !== product.id);
                setWishlistItems(updatedWishlistItems);
                window.location.reload();
            } else {
                console.error('User ID not found in currentUser data');
                navigate('/login');
            }
        } catch (error) {
            console.error('Error removing from wishlist:', error);
        }
    };

    if (!loggedinUser) {
        return (
            <div className="whislist">
                <div className="container">
                    {/* <h2 className="wishlist-title" style={{textAlign: 'center' }}>Wishlist</h2> */}
                    <div className="sidebar_widget wishlist">
                        <div className="empty-wishlist" style={{ minHeight: '1000px', textAlign: 'center' }}>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            
                            <h5 style={{textAlign:'center', fontWeight: '300'}}>Please login to view your wishlist.</h5>
                            <br/>
                            <Link to='/login'><Button size='small' variant='contained'>Login</Button></Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (wishlistProducts.length === 0) {
        return (
            <div className="whislist">
                <div className="container">
                    {/* <h2 className="wishlist-title" style={{textAlign: 'center' }}>Wishlist</h2> */}
                    <div className="sidebar_widget wishlist">
                        <div className="empty-wishlist" style={{ minHeight: '1000px', textAlign: 'center' }}>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            {/* <img src={emptywishlist} alt="Empty Wishlist" style={{ maxWidth: '100%', height: 'auto' }}/> */}
                            <h5 style={{textAlign:'center', fontWeight: '300'}}>Your Wishlist is looking a little empty!<br/> Start adding your favorite items and make it uniquely yours. Happy shopping! </h5>
                            <br/>
                            <Link to='/'><Button size='small' variant='contained'>Continue Shopping</Button></Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
        
    return (
            <div className="whislist">
                <div className="container">
                    <h2 className="wishlist-title">Wishlist</h2>
                    <div className="sidebar_widget wishlist mb-35">
                        {wishlistProducts.map((product, index) => (
                            <div className="cart_item" key={index}>
                                <div className="cart_remove">
                                    <button className='btn btn-sm' title="Remove this item" onClick={() => handleRemoveFromWishlist(product)}>
                                        <img src={delIcon} alt="delIconimg" height={20} />
                                    </button>
                                </div>
                                <div className="cart_img">
                                    <a href="#">
                                        <img src={`data:image/jpeg;base64,${atob(product.image)}`} alt={product.name} />
                                    </a>
                                </div>
                                <div className="cart_info">
                                <Link to={`/product_info/${product.id}`}>
                                        {product.name}
                                </Link>
                                    <span className="cart_price">Price: {product.price}</span>
                                </div>
                                <div className="cart_add">
                                    {isInCart(product.id) ? (
                                        <Button variant='outlined' disabled>
                                            Added to Cart
                                        </Button>
                                    ) : (
                                        <>
                                        <Button variant='outlined' onClick={() => handleAddToCart(product)}>
                                            Add to Cart
                                        </Button>
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };
