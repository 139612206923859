// TrendingItemsCarousel.js
import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useProductContext } from '../../Contexts/ProductContext';
import { Link } from 'react-router-dom';
import './Trending.css';

const TrendingItemsCarousel = () => {
  const { products } = useProductContext();

  const decodeBase64Image = (base64) => {
    const decodedImage = new Image();
    decodedImage.src = `data:image/jpeg;base64,${atob(base64)}`;
    return decodedImage.src;
  };

  const trendingProducts = products.filter((product) => product.trending);


  const carouselOptions = {
    items: 4,
    loop: false,
    margin: 20,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 1 // Show only one item in mobile view
      },
      479: {
        items: 2 // Show two items in tablet view
      },
      700: {
        items: 3
      },
      800: {
        items: 4 // Show four items in desktop view
      },
      1093: {
        items: 5
      }
    }
  };

  // const fixedImageHeight = '180px'; // Set your desired fixed height

  const truncateNameForURL = (name, maxLength) => {
    const words = name.split(' ');
    if (words.length <= maxLength) {
      return words.join('-'); // If the number of words is less than or equal to maxLength, use all words
    }
    return words.slice(0, maxLength).join('-'); // Otherwise, use only the first maxLength words
  };

  return (
    <div className="carousel-box pt-3 pb-5">
      <div className="container-fluid">
        <h2 className="text-center mb-5">Shop Our Trending Items</h2>
        <OwlCarousel className="owl-carousel3 owl-theme" {...carouselOptions}>
          {trendingProducts.map((item, index) => (
            <div key={index} className="item">
              <div className="shop1">
                <Link
                  to={`/${item.category_hierarchy.join('/')}?product=${encodeURIComponent(truncateNameForURL(item.name, 4))}-${encodeURIComponent(item.id)}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}>
                  <img
                    src={decodeBase64Image(item.image)}
                    alt=""
                    title=""
                    loading="lazy"
                    className="img-fluid"
                    style={{
                      objectFit: 'contain',
                      borderRadius: 0,
                    }}
                  />
                  <div className=""></div>
                  <div className="shop-content">
                    <h3>{item.name}</h3>
                    <span className="old-price" style={{ fontSize: '24px', color: 'black' }}>
                      <span style={{
                        fontSize: '14px',
                        position: 'relative',
                              top: '-7px',
                              fontSize: '13px',
                              paddingRight: '3px'}}>$</span>{item.price}
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default TrendingItemsCarousel;
