// ProductContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const ProductContext = createContext();

export const useProductContext = () => useContext(ProductContext);

export const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  // const path = window.location.pathname;
  // const companyID = path.split('/').pop();
  // const endpoint = `/get_products?companyID=${encodeURIComponent(companyID)}`; 
  const companyName = localStorage.getItem('companyName');
  const dynamicURL = companyName ? `/get_products?companyName=${encodeURIComponent(companyName)}` : '/';
  
  const getProductUrl = `${apiUrl}${dynamicURL}`;
  
  useEffect(() => {
    
    console.log("company name ============ ",companyName);
    const getProducts = async () => {
      try {
        // const headers = {
        //   'Authorization': `${secretKey}`, // Replace with your actual authorization header
        //   'Content-Type': 'application/json', // Adjust content type if needed
        // };

        // You can include any necessary parameters in the URL
        const response = await fetch(getProductUrl, {
          method: 'GET',
          //headers: headers,
        });
        const data = await response.json();
        const banner_value = data[0];

        // const bannerobj_val = banner_value['bannerObjVal'];
        setProducts(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    getProducts();
  }, [companyName,getProductUrl,dynamicURL]);
  
  return (
    <ProductContext.Provider value={{ products }}>
      {children}
    </ProductContext.Provider>
  );
};
